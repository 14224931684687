app.component( 'refDisplayer', {
    templateUrl: '/templates/comp/ref-displayer.html',
    bindings: {

        showTitle: '<',
        showNarrative: '<',
        lang: '<',
        ref: '<'
    },

    controller: function( $rootScope, $window, HelperService ) {
        var ctrl = this;

        ctrl.showTitle = false;
        ctrl.showNarrative = false;
        ctrl.lang = 'en';
        ctrl.user = $rootScope.user;

        ctrl.getDocumentDisplayTextSingleLang = function(  ) {
            if( !ctrl.ref.desc || ctrl.ref.desc.length <= ctrl.langIndex ) {
                return ctrl.ref.uri;
            }
            var res = "";
            if( ctrl.ref.desc.length > $rootScope.langIndex && ctrl.ref.desc[ ctrl.langIndex ].value ) {
                res += (res.length ? ' - ' : '') + ctrl.ref.desc[ ctrl.langIndex ].value;
            }
            if( res.length == 0 ) {
                return ctrl.ref.uri;
            }
            return res;

        };

        ctrl.getDocumentDisplayText = function( ) {
            if( !ctrl.ref ) {
                return ''
            }
            if(!ctrl.ref.desc || ctrl.ref.desc.length == 0 ) {
                return ctrl.ref.uri;
            }

            if( ctrl.langIndex <= 1 ) {
                return ctrl.getDocumentDisplayTextSingleLang();
            }
            var res = "";
            if(  ctrl.ref.desc[ 0 ].value ) {
                res += ( res.length ? ' - ' : '' ) + ctrl.ref.desc[ 0 ].value;
            }
            if( ctrl.ref.desc.length > 1 && ctrl.ref.desc[ 1 ].value ) {
                res += ( res.length ? ' - ' : '' ) + ctrl.ref.desc[ 1 ].value;
            }
            if( res.length == 0 ) {
                return ctrl.ref.uri;
            }
            return ( ctrl.ref.format === "OLISRef" ?  res + " - " + ctrl.ref.uri : res );
        };


        ctrl.buildOlisUrl = function( ) {
            return HelperService.buildOlisUrl( ctrl.ref.uri, $rootScope.lang );
        };

        ctrl.openOlisUrl = function( ) {
            var url = HelperService.buildOlisUrl( ctrl.ref.uri, $rootScope.lang );
            if( url ) {
                $window.open( url, '_blank' );
            }
        };

        ctrl.$onInit = function() {
            if( ctrl.lang === 'en' ) {
                ctrl.langIndex = 0;
            } else if( ctrl.lang === 'fr' ) {
                ctrl.langIndex = 1;
            } else if( ctrl.lang === 'enfr' ) {
                ctrl.langIndex = 2;
            } else {
                ctrl.langIndex = 0;
            }
        };
    }
});