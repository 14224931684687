app.controller( "AdherencesEditCtrl", function( $scope ) {
    
    var self = this;
    
    
    self.addNewNonMemberAdherence = function( mode, cou ) {
        var list = self.getNonMemberList( mode );
        list.push( {
                       country: mode == 'International organisation' ? $scope.lists.countries.hashset['233'] : {}, //EU by default
                       documentRef: [],
                       comments: {
                            text: []
                        },
                       status: { id: 2 }
                   } );
    };



    self.addMemberExceptionalAdherence = function( adherence ) {
        var mindex = $scope.instrument.adherence.memberCountries.findIndex( function( adh ) {
            return adh.country.id === adherence.country.id;
        }  );
    
        if( mindex > -1 ) {
            $scope.instrument.adherence.memberCountries.splice( mindex, 1 );
        }
    
        var xindex = !$scope.instrument.adherence.memberExceptionals.findIndex( function( adh ) {
            return adh.country.id === adherence.country.id;
        } );

        if( mindex > -1 ) {
            //move to exp. list
            $scope.instrument.adherence.memberExceptionals.splice( xindex, 0, adherence );
            
            for( ii = 0; ii < adherence.attachments.length; ii++ ) {
                $scope.instrument.adherence.memberExceptionals.splice( xindex + ii + 1, 0, adherence.attachments[ ii ] );
            }
        } else {
            //crete a duplicate from exp.list
            var adh = { country: adherence.country, status: { id: -1 } };
            $scope.instrument.adherence.memberExceptionals.push( adh );
            //$scope.instrument.adherence.memberExceptionals.push( angular.copy( adherence ) );
        }
    };

    self.getNonMemberList = function( mode ) {
        if( !$scope.instrument.adherence ) {
            return [];
        }
        if( mode == 'International organisation' ) {
            return $scope.instrument.adherence.others;
        }
        return $scope.instrument.adherence.nonMemberCountries;
    };

    self.deleteNonMemberAdherence = function( mode, index ) {
        var list = self.getNonMemberList( mode );
        if( index > -1 ) {
            list.splice( index, 1 );
        }
    };
    
    self.deleteMemberExceptionalAdherence = function( index ) {
        if( index > -1 ) {
            var item = $scope.instrument.adherence.memberExceptionals[ index ];
            var xindex = $scope.instrument.adherence.memberExceptionals.findIndex( function( adh ) {
                return adh.country.id === item.country.id && adh !== item;  } );
    
            $scope.instrument.adherence.memberExceptionals.splice( index, 1 );
            if( xindex === index || xindex < 0 ) {
                //add an ordinary adherence record for the member country as this one was the last exception
                $scope.instrument.adherence.memberCountries.push( { country: item.country, status: {id: 1 }  } );
                $scope.instrument.adherence.memberCountries.sort( countrySort );
            }
        }
    };
    
    self.isValidAdherence = function( field, idx ) {
        var ref = 'adherence.item[ '+ idx + '].'+ field;
        return ($scope.invalidValues.indexOf( ref ) > -1) && editInstrumentForm[ ref ].$pristine;
    };

} );
