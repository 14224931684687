app.controller( "InstrumentDetailCtrl", function(
    $scope,
    $rootScope,
    $sce,
    $filter,
    $window,
    $timeout,
    $state,
    $stateParams,
    $location,
    $http,
    $anchorScroll,
    InstrumentService,
    PrintoutService,
    FlashMessageService,
    HelperService ) {


    $scope.extractAppendices = function( htmlText ) {

        var doc = parseHtml( htmlText );
        if( doc == null ) {
            return [];
        }
        var res = [];
        var id;
        var nl = doc.getElementsByName( "Appendix" );
        var title;
        var ch;
        for( ii = 0; ii < nl.length; ii++ ) {
            ch = nl[ ii ].children;
            if( ch.length > 0 && ch[ 0 ].innerHTML ) {
                title = ch[ 0 ].innerHTML;
            } else if( nl && nl.innerHTML ) {
                title = nl.innerHTML;
            } else {
                title = null;
            }
            if( title ) {
                id = "Appendix_" + ii;
                nl[ ii ].id = id;
                res.push( { id: id, title: title, isSubItem: true } );
            }
        }
        var styles = doc.getElementsByTagName( "STYLE" );

        if( styles && styles.length && doc.body ) {
            var style = styles[ 0 ];
            //make header styles not impact other headers in the page
            style.innerHTML = style.innerHTML.replace( /\n\s*(h[1-9])\n*\s*{/ig, "\r\ninstrument-wrapper.$1 {" );
            doc.body.insertBefore( style, doc.body.firstChild );
        }
        return [ res, doc.body ? doc.body.innerHTML : null ];
    };

    $scope.jumpToAnchor = function( anchor ) {
        aelem = document.getElementById( anchor );
        if(aelem) aelem.scrollIntoView( true );
        if($location.hash() !== anchor) $location.hash( anchor );
        //$anchorScroll();
    };

    //$scope.$watch( "selectedPivot", function() {
    //    $scope.jumpToAnchor( $scope.selectedPivot.id );
    //    $window.scrollTo( 0, 0 );
    //} );
    $scope.langIndex = $rootScope.langIndex;

    $scope.showCountryPopup = function( list, adherence ) {
        if( $scope.activePopup && adherence != $scope.activePopup ) {
            $scope.activePopup.popupOpen = false;
        }
        if( adherence.attachments && adherence.attachments.length === 0 && (adherence.comments.text.length <= $scope.langIndex || !adherence.comments.text[ $scope.langIndex ].value) && adherence.documentRef && adherence.documentRef.length === 0 && !adherence.showAsterisk && !adherence.showStrikeThru ) {
            //$scope.viewOtherAdherences( adherence.country.id );
            return;
        }
        if( adherence.popupOpen ) {
            adherence.popupOpen = false;
            return;
        }
        adherence.popupOpen = true;
        $scope.activePopup = adherence;
    };

    $scope.canShowStatusText = function( adherence ) {
        return adherence.showAsterisk || adherence.showStrikeThru || (adherence.attachments && adherence.attachments.length > 0);
    };

    $scope.getAdherenceStatusText = function( adherence ) {
        if( adherence.country.kind === 'Member' ) {
            if( adherence.adherenceStatus && adherence.adherenceStatus.id !== 1 ) {
                return adherence.adherenceStatus.name;
            }
            if( adherence.status.id !== 1 ) {
                return adherence.status.name;
            }
            return '';
        }

        if( adherence.adherenceStatus && adherence.adherenceStatus.id >= 3 ) {
            return adherence.adherenceStatus.name;
        }

        if( adherence.status.id >= 3 ) {
            return adherence.status.name;
        }
        return '';
    };

    $scope.viewOtherAdherences = function( countryId ) {
        $state.go( "app.adherences", { adherentIds: countryId, lang: $scope.lang } );
    };

    function shouldShowAsterisk( item, langIndex ) {

        if( item.showAsterisk ) {
            return true;
        }

        if( (item.comments.text.length > langIndex && (item.comments.text[ langIndex ]).value) ) {
            return true;
        }

        return false;
    };

    $scope.prepareForView = function( $scope, instrument, lists ) {
        $scope.lists = lists;
        $scope.instrument = instrument;

        var notesValue = _.get(instrument, 'notes.value');
        if(!_.isEmpty(notesValue)) {
            var $div = angular.element("<div></div>");
            $div.append($sce.valueOf(notesValue));
            angular.forEach($div.find('a'), function(link) {
                var linkHref = link.getAttribute('href');
                if(_.endsWith(linkHref, '.pdf') && link.target !== '_blank') {
                    link.target = '_blank';
                }
            });
            instrument.notes.value = $sce.trustAsHtml($div.html());
        }

        if( !$scope.instrument.background.text[ $scope.langIndex ].value && !$scope.instrument.blurb.text[ $scope.langIndex ].value ) {
            $scope.pivots.delete( function( it ) {
                return it.id === "backgroundInformation";
            } );
        }

        if( $scope.instrument.committees.parentCommittee.length + $scope.instrument.committees.involvedCommittee.length === 0 ) {
            $scope.pivots.delete( function( it ) {
                return it.id === "committees";
            } );
        }


        if( ($scope.instrument.relations.replacedBy.length + $scope.instrument.relations.relatedTo.length + $scope.instrument.relations.replaces.length) === 0 ) {
            $scope.pivots.delete( function( it ) {
                return it.id === "relatedInstruments";
            } );
        }

        if( !$scope.filterSupportDocuments().length  ) {
            $scope.pivots.delete( function( it ) {
                return it.id === "supportDocuments";
            } );
        }

        var hasMonitoringEntries = !_.isEmpty($scope.instrument.monitoring.entry);
        var hasAnyMonitoringComments = hasMonitoringEntries && _.some($scope.instrument.monitoring.entry, function (entry) {
            return _.some(_.get(entry, 'comments.text'), function (txt) {
                return !_.isEmpty(_.get(txt, 'value'))
            })
        });

        if(!hasAnyMonitoringComments) {
            $scope.pivots.delete( function( it ) {
                return it.id === "monitoring";
            } );
            $scope.hideMonitoringSection = true;
        }

        if( !$scope.instrument.notes.value ) {
            $scope.pivots.delete( function( it ) {
                return it.id === "leg";
            } );
        }

        var translations = _.get($scope, 'instrument.translations.translation');
        if(_.isEmpty(translations)) {
            $scope.pivots.delete(function( it ) {
                return it.id === "translations";
            });
        } else {
            $scope.instrument.translations.translation = _.orderBy(translations, [function(tran) {
                var tranLang = _.find(lists.languages, function(lang) {
                    return lang.id == tran.language;
                });
                return _.get(tranLang, 'name', tran.id);
            }], ['asc']);
        }

        angular.forEach( instrument.adherence.memberCountriesForView, function( adherence ) {
            adherence.adherenceStatus = HelperService.findListItem( lists.adherenceTypes, adherence.adherenceStatus );
        } );

        angular.forEach( instrument.adherence.nonMemberCountriesForView, function( adherence ) {
            adherence.showAsterisk = adherence.showAsterisk || shouldShowAsterisk( adherence, $rootScope.langIndex );
            adherence.adherenceStatus = HelperService.findListItem( lists.adherenceTypes, adherence.adherenceStatus );
        } );

        angular.forEach( instrument.adherence.othersForView, function( adherence ) {
            adherence.showAsterisk = adherence.showAsterisk || shouldShowAsterisk( adherence, $rootScope.langIndex );
            adherence.adherenceStatus = HelperService.findListItem( lists.adherenceTypes, adherence.adherenceStatus );

        } );

        if($location.hash()) {
            $timeout(function() {
                $scope.jumpToAnchor($location.hash());
            }, 500);
        }
    };

    $scope.updateAppendices = function( appendices ) {
        if( appendices && appendices.length ) {
            var ii = appendices.length - 1;
            for( ; ii >= 0; ii-- ) {
                var app = appendices[ ii ];
                app.name = "subItem";
                $scope.pivots.splice( 1, 0, app );
            }
        }
    };

    $scope.loadForView = function( $scope, id, preview ) {
        InstrumentService.fetch( id, preview ).then( function( formatted ) {

            if( preview ) {
                $scope.draftText = true;
            }

            var lists = formatted[ 0 ];
            var instrument = formatted[ 1 ];

            var titleDates = _.filter(instrument.changeHistory.update, function(entry) {
                return _.get(entry, 'type.id') !== 9;
            });
            if(_.size(titleDates) > 2 ) titleDates = [_.head(titleDates), _.last(titleDates)];
            instrument.titleDates = titleDates;

            var ref = instrument.bodyText.ref[ $rootScope.langIndex ];
            if( ref && ref.uri ) {
                if( ref.format === "pdf" ) {
                    var uri = ref.uri + "#view=FitH&scrollbar=1&toolbar=0&navpanes=0";
                    $scope.pdfObject = $sce.trustAsHtml( '<object data="' + uri + '" type="application/pdf" height="900px" width="100%">' + '<p>Your browser does not suppport viewing PDF files, ' + 'or not configured properly to display embedded pdf files.</p>' + '</object>' );

                    $scope.textUrl = $sce.trustAsUrl( uri );
                    $scope.textType = "application/pdf";
                } else {
                    $scope.textUrl = ref.uri;
                    $scope.textType = "text/html";
                    $http.get($scope.textUrl ).then( function( response ) {
                        var txt = $scope.extractAppendices( response.data );
                        if( txt.length == 2 ) {
                            //todo: IE still does recognize work with regex 3 below
                            /*ex. <span style="font-size: 10pt;">(iii)<span style="font-size-adjust: none; font-stretch: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              </span></span>
                             */
                            // remove ms word's html converter's oddities
                            var html = txt[ 1 ].replace(/font(?:-family)?:((("|'|&quot;)?[\w\s-]+)\3\s?,?\s?)+/ig, "")
                                               .replace(/font(?:-size)\s*:\s*[0-9]*(\.[0-9]+)?(pt|px|em|")?;?/ig, "")
                                               .replace( /<span[\s0-9a-zA-Z\-"';%\.:&=]*>(?:\s?&nbsp;\s?)+<\/span>/ig,
                                                         '<span style="margin-right:9pt"></span>' )
                                               .replace(/<span([\s0-9A-Za-z"':;%&=,\.\-]*)>((\(?[0-9a-zA-Z]{0,}(\.|•|-|·|●)?\)?){0,6}[0-9a-zA-Z]?(\.|\))?)(<[a-zA-Z]*>)?(\s|&nbsp;){2,}(<\/[a-zA-Z]*>)?/img,
                                                         "<span style='margin-right:9pt;'>$2</span><span$1>")
                                               //ATTN! the dot characters below are NOT the same!
                                               .replace( /<span style='margin-right:9pt;'>\s*[•·]\s*</img, "<span>●<" )
                                               //.replace( /<span[\s0-9a-zA-Z\-"';%\.:&=]*>\s*[·\.]\s*</img, "<span>●<" )
                                               .replace( /text-indent:\s*-[0-9]*\.?[0-9]+(pt|cm|mm|px|in|em|ex|pc)/img, "" );

                            // extract links from instrument main text
                            var htmlElement = angular.element(html);
                            var pushToPivots = [];
                            var useAngularElement = false;
                            angular.forEach(htmlElement.find('a'), function(link) {
                                var linkHref = link.getAttribute('href');
                                if (linkHref === '#' && link.innerText) {
                                    var linkSlug = slugify(link.innerText);
                                    pushToPivots.push({
                                        id: linkSlug,
                                        name: link.innerText,
                                        title: link.innerText,
                                        level: 2
                                    });
                                    link.id = linkSlug;
                                    link.removeAttribute('href');
                                    link.classList.add('licomp-details-htmlText-anchor');
                                    useAngularElement = true;
                                } else if(_.endsWith(linkHref, '.pdf') && link.target !== '_blank') {
                                    link.target = '_blank';
                                    useAngularElement = true;
                                }
                            });
                            if(useAngularElement) {
                                if(pushToPivots.length > 0) {
                                    $scope.pivots.splice.apply($scope.pivots, [1, 0].concat(pushToPivots));
                                }
                                var $div = angular.element("<div></div>");
                                $div.append(htmlElement);
                                html = $div.html();
                            }

                            $scope.htmlText = $sce.trustAsHtml( html )
                        }
                        if( txt.length > 1 ) {
                            $scope.updateAppendices( txt[ 0 ] );
                        }
                    } );
                }
            } else {
                $scope.textUrl = "";
                $scope.textType = "text/html";
            }

            if( instrument.bodyText.intro.text.length > $rootScope.langIndex && instrument.bodyText.intro.text[ $rootScope.langIndex ].value ) {
                $scope.introText = instrument.bodyText.intro.text[ $rootScope.langIndex ].value;
            } else {
                $scope.introText = "";
            }

            if( (instrument.relations.replacedBy.length + instrument.relations.relatedTo.length + instrument.relations.replaces.length) > 0 ) {
                InstrumentService.quickSearch( "" ).then( function( data ) {
                    instrument.relations.replacedBy.joinedIterator( [ instrument.relations.relatedTo, instrument.relations.replaces ],
                                                                    function( item ) {
                                                                        var di = data.hashset[ "" + item.id ];
                                                                        if( di ) {
                                                                            item.key = di.key;
                                                                            item.title = di.title;
                                                                        }
                                                                    } );
                } );
            }
            $scope.prepareForView( $scope, instrument, lists );

        }, function( error ) {
            if( error.status === 404 ) {
                $state.go( "app.404", { lang: $rootScope.lang } );
                FlashMessageService.show( "Instrument not found", "error" );
            }
        } );
    };


    // we translate those labels in controller because ngOfficeUiFabric directives are not compatible with filters...
    $scope.translation = {
        printout: {
            printSingleWithMainText: $filter( "translate" )( "printout.printSingleWithMainText" ),
            printSingleWithMainTextAndMetaData: $filter( "translate" )( "printout.printSingleWithMainTextAndMetaData" )
        }
    };

    $scope.showDownloadInstrument = function() {
        var instrument = $scope.instrument;
        if(!instrument) return false;

        if(instrument.bookletDeactivated) return false;

        var haveManualBooklet = instrument.manuallyUploadedBooklet[$scope.lang];
        return haveManualBooklet || !instrument.bodyText.ref[$scope.langIndex].uri.endsWith('.pdf');
    };

    $scope.printInstrument = function() {
        PrintoutService.create( [ $scope.instrument.id ], $scope.draftText );
    };

    $scope.buildOlisUrl = function( uri, lang ) {
        return HelperService.buildOlisUrl( uri, lang );
    };

    $scope.openOlisUrl = function( uri, lang ) {
        var url = HelperService.buildOlisUrl( uri, lang );
        if( url ) {
            $window.open( url, '_blank' );
        }
    };

    $scope.openSeachPageForTheme = function( thx ) {
        var params = {
            mode: 'advanced', themeIds: thx.id
        };
        $state.go( 'app.instruments', params );

    };

    $scope.openSeachPageForType = function( type ) {
        var params = {
            mode: 'advanced', typeIds: type.id
        };

        $state.go( 'app.instruments', params );

    };

    $scope.filterSupportDocuments = function() {
        if(! $scope.instrument ) {
            return [];
        }
        return $scope.instrument.bodyText.support.filter( function( value ) {
            return value.desc && value.desc[ $scope.langIndex ] && value.desc[ $scope.langIndex ].value ? true : false;
        } );

    };

    $scope.isQuillEditedText = function() {
        return /class=("|')ql-/im.test( $scope.htmlText )
    };


    $scope.pivots = [
        {
            id: "mainText", title: $filter("translate")("instrument.titles.mainText")
        },
        {
            id: "backgroundInformation", title: $filter("translate")("instrument.titles.backgroundInformation")
        },
        {
            id: "supportDocuments", title: $filter("translate")("instrument.titles.supportDocuments")
        },
        {
            id: "translations", title: $filter("translate")("instrument.titles.unofficialTranslations")
        },
        {
            id: "committees", title: $filter("translate")("instrument.titles.committees")
        },
        {
            id: "dates", title: $filter("translate")("instrument.titles.dates")
        },
        {
            id: "relatedInstruments", title: $filter("translate")("instrument.titles.relatedInstruments")
        },
        {
            id: "monitoring", title: $filter("translate")("instrument.titles.monitoring")
        },
        {
            id: "adherents", title: $filter("translate")("instrument.titles.adherents")
        },
    ];


    // Tabs for users with privileges
    /*
	if( !$rootScope.user.canDo( "viewMonitoring" ) ) {
        $scope.pivots.delete( function( it ) {
            return it.id === "monitoring";
        } );
    }
	*/
    if( $rootScope.user.canDo( "viewLegNotes" ) ) {
        $scope.pivots.push( { id: "leg", title: $filter( "translate" )( "instrument.titles.legInternalNotes" ) } );
    }

    $scope.selectedPivot = $scope.pivots[ 0 ];

    $scope.loadForView( $scope, $stateParams.id, $stateParams.preview === "true" );


} );


function slugify(string) {
    var a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
    var b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
    var p = new RegExp(a.split('').join('|'), 'g')

    return string.toString().toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(p, function(c) { return b.charAt(a.indexOf(c)); }) // Replace special characters
      .replace(/&/g, '-and-') // Replace & with 'and'
      .replace(/[^\w\-]+/g, '') // Remove all non-word characters
      .replace(/\-\-+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, '') // Trim - from end of text
}
