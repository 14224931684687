app.service('ConceptService', function (API_BASE_URL, $http, MarkLogicApiAdapter) {

  var serviceBaseUrl = API_BASE_URL + 'concepts';

  this.list = function () {
    return $http.get(serviceBaseUrl).then(function (results) {
      return MarkLogicApiAdapter.formatConcepts(results.data);
    });
  };

  this.update = function (concepts) {
    return $http.put(serviceBaseUrl, MarkLogicApiAdapter.unformatConcepts(concepts)).then(function (results) {
        angular.forEach( concepts, function( concept ) {
            delete concept.modified;
        } )
        return results.data;
    });
  };

});
