app.controller( "InstrumentListCtrl",
    function( $rootScope,
              $scope,
              $sce,
              $window,
              $state,
              $filter,
              $stateParams,
              MarkLogicApiAdapter,
              InstrumentService,
              ListService,
              HelperService,
              CommitteeService,
              PublishService,
              FlashMessageService ) {

        // we translate those labels in controller because ngOfficeUiFabric directives are not compatible with filters...
        $scope.translation = {
            printout: {
                printSelection: $filter( "translate" )( "printout.printSelection" ),
                printSelectionWithMainText: $filter( "translate" )( "printout.printSelectionWithMainText" ),
                printSelectionWithMainTextAndMetaData: $filter( "translate" )( "printout.printSelectionWithMainTextAndMetaData" )
            },
            buttons: {
                preview: $filter( "translate" )( "instruments.buttons.preview" ),
                open: $filter( "translate" )( "instruments.buttons.open" ),
                edit: $filter( "translate" )( "instruments.buttons.edit" )
            }
        }

        if( $stateParams.mode === "publish" && !$rootScope.user.canDo( "publishInstruments" ) ) {
            return $state.go( "app.403" );
        }

        $scope.resetParams = function() {

            $scope.instruments.length = 0;
            $scope.selectedInstruments.length = 0;
            $scope.searchData.yearFrom = [];
            $scope.searchData.yearTo = [];
            $scope.searchData.dateFrom = null;
            $scope.searchData.dateTo = null;
            $scope.loaded = false;

            angular.forEach( $scope.lists.instrumentTypes, function( item ) {
                item.ticked = false;
            } );

            angular.forEach( $scope.lists.countries, function( item ) {
                item.ticked = false;
            } );

            angular.forEach( $scope.lists.instrumentStatusTypes, function( item ) {
                item.ticked = false;
            } );

            angular.forEach( $scope.lists.themes, function( item ) {
                item.ticked = false;
            } );

            angular.forEach( $scope.lists.committees, function( item ) {
                item.ticked = false;
            } );

            angular.forEach( $scope.lists.year1, function( item ) {
                item.ticked = false;
            } );
            angular.forEach( $scope.lists.year2, function( item ) {
                item.ticked = false;
            } );

            $scope.searchData.term = "";
            $scope.searchData.reference = "";
            $scope.searchData.types.length = 0;
            $scope.searchData.committees.length = 0;
            $scope.searchData.themes.length = 0;
            $scope.searchData.status.length = 0;
            $scope.searchData.adherents.length = 0;
            $scope.searchData.dateType = "";
            $scope.searchData.searchScope = "fullText";
            $scope.searchData.searchType = "allWords";
            $scope.searchData.monitoringType = "";
            $scope.searchData.monitoringYear = [];

            $scope.searchData.adherentIds = "";
            $scope.searchData.typeIds = "";
            $scope.searchData.committeeIds = "";
            $scope.searchData.statusIds = "";
            $scope.searchData.themeIds = "";


            if( $stateParams.mode === "normal" ) {
                $scope.searchData.dateType = "adoption";
            } else if( $stateParams.mode === "publish" ) {
                $scope.searchData.dateType = "lastPublished";
            }
        };

        $scope.updateState = function() {
            var sp = $scope.buildNewState( $scope.searchData );
            $state.go('app.instruments', sp, {  // prevent the events onStart and onSuccess from firing
                notify:false,
                // prevent reload of the current state
                reload:false,
                // replace the last record when changing the params so you don't hit the back button and get old params
                location:'replace',
                // inherit the current params on the url
                inherit:false } );
        };

        $scope.clear = function() {
            $scope.resetParams();
            $scope.updateState();
        };


        $scope.displayDate = function( dt ) {
            return $scope.dateType === dt || ( $scope.dateType === 'abrogation' && dt === 'adoption' );
        };

        $scope.getDisplayDateColumn = function() {

            if( $stateParams.mode === "publish" || $scope.instruments.length === 0 ) {
                return "";
            }

            var prop = $scope.searchData.dateType + "Date";
            for( ii = 0; ii < $scope.instruments.length; ii++ ) {
                if( $scope.instruments[ ii ][ prop ] ) {
                    return $scope.searchData.dateType;
                }
            }

            var sl = $scope.searchData.status;
            for( ii = 0; ii < sl.length; ii += 1 ) {
                if( sl[ ii ].id === 2 ) { // 2 = abrogated
                    return "abrogation";
                }
            }
            return "";

        };


        $scope.search = function( searchData ) {

            $scope.instruments.length = 0;
            $scope.loading = true;
            $scope.loaded = false;
            $scope.dateType = "";


            if( $stateParams.mode === "publish" ) {

                // we use "false" string instead of BOOL because BOOLs are not compatible with ngOfficeUiFabric "uif-dropdown-option"
                if( searchData.published === "false" ) {
                    InstrumentService.listDrafts().then( function( instruments ) {
                        $scope.loading = false;
                        $scope.instruments = instruments;
                        $scope.dateType = $scope.getDisplayDateColumn();

                        $scope.loaded = true;
                    }, function( error ) {
                        $scope.loading = false;
                        FlashMessageService.show( "Could not fetch draft list", "error" );
                    } );
                }

                // we use "false" string instead of BOOL because BOOLs are not compatible with ngOfficeUiFabric "uif-dropdown-option"
                else {
                    InstrumentService.listPublished( searchData ).then( function( instruments ) {
                        $scope.loading = false;
                        $scope.instruments = instruments;
                        $scope.dateType = $scope.getDisplayDateColumn();
                        $scope.loaded = true;
                    }, function( error ) {
                        $scope.loading = false;
                    } );
                }

            } else {
                searchData.typeIds = HelperService.extractIds( searchData.types );
                searchData.committeeIds = HelperService.extractIds( searchData.committees );
                searchData.themeIds = HelperService.extractIds( searchData.themes );
                searchData.statusIds = HelperService.extractIds( searchData.status );
                searchData.adherentIds = HelperService.extractIds( searchData.adherents );

                InstrumentService.list( searchData ).then( function( instruments ) {

                    angular.forEach( instruments, function( instrument ) {
                        if( instrument.excerpt ) {
                            instrument.excerpt = $sce.trustAsHtml( instrument.excerpt );
                        }
                        if( instrument[ "title-highlight" ] ) {
                            instrument[ "title-highlight" ] = $sce.trustAsHtml( instrument[ "title-highlight" ] );
                        } else {
                            delete instrument[ "title-highlight" ];
                        }
                    } );

                    $scope.loading = false;
                    $scope.instruments = instruments;
                    $scope.dateType = $scope.getDisplayDateColumn();
                    if( !$scope.dateType ) {
                        $scope.dateType = "adoption";
                    }
                    $scope.loaded = true;

                    // scroll to instruments
                    var table = document.querySelector( "#instruments-table" );
                    if( table ) {
                        table.scrollIntoView();
                    }
                    $scope.searchData = searchData;
                    $scope.loadedQuery = _.cloneDeep(searchData);
                    $scope.updateState();
                    window.scrollBy( 0, -40 );

                }, function( error ) {
                    $scope.loading = false;
                    FlashMessageService.show( $filter( "translate" )( "filters.searchError" ), "error" );
                } );
            }
        };

        $scope.buildNewState = function( searchData ) {
            var sp = MarkLogicApiAdapter.unformatInstrumentParams( searchData );
            
            delete sp[ searchData.dateType + "DateRange" ];
            if( searchData.yearFrom.length ) {
                sp.yearFrom = searchData.yearFrom[ 0 ].name;
            }
            if( searchData.yearTo.length ) {
                sp.yearTo = searchData.yearTo[ 0 ].name;
            }
            if( searchData.dateType ) {
                sp.dateType = searchData.dateType;
            }

            sp.mode = $stateParams.mode;
            return sp;

        };

        $scope.publish = function( instruments ) {
            var ids = HelperService.extractIds( instruments );
            PublishService.publish( ids ).then( function() {
                $state.reload();
                FlashMessageService.show( "Selected instruments published, please verify the list", "success" );
            }, function( error ) {
                FlashMessageService.show( "Could not publish selected instruments", "error" );
            } );
        };

        $scope.unpublish = function( instruments ) {
            var ids = HelperService.extractIds( instruments );
            PublishService.unpublish( ids ).then( function() {
                $state.reload();
                FlashMessageService.show( "Selected instruments un-published, please verify the list", "success" );
            }, function( error ) {
                FlashMessageService.show( "Could not un-publish selected instruments", "error" );
            } );
        };

        $scope.scrollTop = function() {
            $window.scroll({
                               top: 0,
                               left: 0,
                           });
        };


        $scope.instruments = [];
        $scope.selectedInstruments = [];
        $scope.searchData = {
            term: "",
            reference: "",
            yearFrom: [],
            yearTo: [],
            types: [],
            committees: [],
            themes: [],
            status: [],
            adherents: [],
            dateType: "inForce",
            searchScope: "fullText",
            searchType: "allWords",
            monitoringType: "",
            monitoringYear: [],
            published:  "false",
            dateFrom: null,
            dateTo: null
        };

        $scope.lists = {
            committees: [],
            themes: [],
            instrumentStatusTypes: [],
            countries: [],
            instrumentTypes: [],
            year1: [],
            year2: [],
            year3: []
        };

        ListService.list().then( function( lists ) {

            // Pre-select filters based on state params

            $scope.lists = lists;

            HelperService.removeDisabledItems( lists.countries );
            HelperService.removeDisabledItems( lists.instrumentTypes );
            HelperService.removeDisabledItems( lists.committees );
            HelperService.removeDisabledItems( lists.themes );
            HelperService.removeDisabledItems( lists.instrumentStatusTypes );

            $scope.resetParams();
            if( $stateParams.mode === "publish" ) {
                $scope.searchData.dateType = "lastPublished";
            } else {
                $scope.searchData.term = $stateParams.term;
                $scope.searchData.reference = $stateParams.reference;
                if( $stateParams.yearFrom ) {
                    $scope.searchData.yearFrom = HelperService.selectAndAttachNamesWithIds( lists.year1, $stateParams.yearFrom );
                }
                if( $stateParams.yearTo ) {
                    $scope.searchData.yearTo = HelperService.selectAndAttachNamesWithIds( lists.year2, $stateParams.yearTo );
                }
                $scope.searchData.types = HelperService.selectAndAttachNamesWithIds( lists.instrumentTypes, $stateParams.typeIds );
                $scope.searchData.committees =
                    HelperService.selectAndAttachNamesWithIds( lists.committees, $stateParams.committeeIds );
                $scope.searchData.themes = HelperService.selectAndAttachNamesWithIds( lists.themes, $stateParams.themeIds );
                $scope.searchData.status =
                    HelperService.selectAndAttachNamesWithIds( lists.instrumentStatusTypes, $stateParams.statusIds );

                if( $stateParams.dateType && $stateParams.mode === "advanced" ) {
                    $scope.searchData.dateType = $stateParams.dateType;
                }

                if( $stateParams.searchType ) {
                    $scope.searchData.searchType = $stateParams.searchType;
                }
                if( $stateParams.searchScope ) {
                   $scope.searchData.searchScope = $stateParams.searchScope;
                }
            }

            if( $scope.searchData.reference
                || $scope.searchData.term
                || $scope.searchData.types.length
                || $scope.searchData.yearFrom.length
                || $scope.searchData.yearTo.length
                || $scope.searchData.committees.length
                || $scope.searchData.themes.length
                || $scope.searchData.status.length
                || $stateParams.mode !== "advanced" ) {

                $scope.search( $scope.searchData );
            }
        } );

    } );
