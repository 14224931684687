String.prototype.formatUnicorn = String.prototype.formatUnicorn || function() {
        "use strict";
        var str = this.toString();
        if( arguments.length ) {
            var t = typeof arguments[ 0 ];
            var key;
            var args = ("string" === t || "number" === t) ? Array.prototype.slice.call( arguments ) : arguments[ 0 ];

            for( key in args ) {
                str = str.replace( new RegExp( "\\{" + key + "\\}", "gi" ), args[ key ] );
            }
        }

        return str;
    };

String.prototype.replaceAll = String.prototype.replaceAll || function(search, replacement) {
    var target = this;
    return target.replace(new RegExp(search, 'g'), replacement);
};

Array.prototype.groupBy = Array.prototype.groupBy || function( keyGetter ) {
        var map = {};
        var ii = 0;
        var key;
        var collection;
        var item;
        for( ii = 0; ii < this.length; ii++ ) {
            item = this[ ii ];
            key = keyGetter( item );
            collection = map[ key ];
            if( !collection ) {
                map[ key ] = [ item ];
            } else {
                collection.push( item );
            }
        }
        return map;
    };

Array.prototype.find = Array.prototype.find || function( finder ) {
        var ii;
        var test;
        var item;
        for( ii = 0; ii < this.length; ii++ ) {
            item = this[ ii ];
            test = finder( item );
            if( test ) {
                return item;
            }
        }
    };

Array.prototype.filter = Array.prototype.filter || function( finder ) {
    var ii;
    var test;
    var item;
    var res = []
    for( ii = 0; ii < this.length; ii++ ) {
        item = this[ ii ];
        test = finder( item );
        if( test ) {
            res.push( item );
        }
    }
    return res;
};

Array.prototype.deleteAll = Array.prototype.deleteAll || function( finder ) {
        var ii = 0;
        var test;
        var item;
        while( ii < this.length ) {
            item = this[ ii ];
            test = finder( item );
            if( test ) {
                this.splice( ii, 1 );
            } else {
                ii++;
            }
        }
    };

Array.prototype.delete = Array.prototype.delete || function( finder ) {
        var ii = 0;
        var test;
        var item;
        while( ii < this.length ) {
            item = this[ ii ];
            test = finder( item );
            if( test ) {
                this.splice( ii, 1 );
                return;
            }
            ii++;
        }
    };

Array.prototype.joinedIterator = Array.prototype.mergeIterate || function( otherArrays, iteratorFunction ) {
    var idx;
    var arr;
    var ii = 0;
    while( ii < this.length ){
        iteratorFunction( this[ ii ], ii++ );
    }
    for( jj = 0; jj < otherArrays.length; jj++ ) {
        arr = otherArrays[ jj ];
        idx = 0;
        while( idx < arr.length ){
            iteratorFunction( arr[ idx++ ], ii++ );
        }
    }
};

Element.prototype.remove = function() {
    this.parentElement.removeChild(this);
}

NodeList.prototype.remove = HTMLCollection.prototype.remove = function() {
    for(var i = this.length - 1; i >= 0; i--) {
        if(this[i] && this[i].parentElement) {
            this[i].parentElement.removeChild(this[i]);
        }
    }
}

function parseHtml( htmlText ) {
    try {
        if( typeof window.DOMParser != null ) {
            // modern IE, Firefox, Opera  parse text/html
            var parser = new DOMParser();
            var doc = parser.parseFromString( htmlText, "text/html" );
            if( doc != null ) {
                return doc
            } else {
                //replace ampersands with harmless character string to avoid XML parsing issues
                htmlText = htmlText.replace( /&/gi, "j!J!" );
                //safari parses as text/xml
                var doc = parser.parseFromString( sText, "text/xml" );
                return doc;
            }

        } else {
            // older IE
            var doc = document.implementation.createHTMLDocument( '' );
            doc.write( htmlText );
            doc.close;
            return doc;
        }
    } catch( err ){
        console.log( err );
        return null;
    }
}

Date.prototype.toYMD = function dateToYMD( ) {
    var d = this.getDate();
    var m = this.getMonth() + 1;
    var y = this.getFullYear();
    return "" + y + "-" + (m <= 9 ? "0" + m : m) + "-" + (d <= 9 ? "0" + d : d);
}


Date.prototype.timeStamp = function() {
    var h = this.getHours();
    var m = this.getMinutes();
    var s = this.getSeconds();
    return  this.toYMD()
            + "@" + ( h < 10 ? "0" + h : h  )
            + "-"+ ( m < 10 ? "0" + m : m  )
            + "-"+ ( s < 10 ? "0" + s : s  )
};

function downloadImage(a, canvasId, fileName ) {
    var ts = new Date();
    var canvas = document.getElementById( canvasId );
    var name = ( fileName === undefined ? canvasId : fileName ) + "-" + ts.timeStamp() + ".png";
    if( canvas.msToBlob ) { //IE
        var blob = canvas.msToBlob();
        window.navigator.msSaveBlob( new Blob( [blob], { type:"image/png" } ), name );
    } else {
        var image = canvas.toDataURL( 'image/png' );
        a.href = image;
        a.download = name;
    }
}

function getFirstBrowserLanguage() {
    var nav = window.navigator,
        browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'],
        i,
        language;

    // support for HTML 5.1 "navigator.languages"
    if (Array.isArray(nav.languages)) {
        for (i = 0; i < nav.languages.length; i++) {
            language = nav.languages[i];
            if (language && language.length) {
                return language;
            }
        }
    }

    // support for other well known properties in browsers
    for (i = 0; i < browserLanguagePropertyKeys.length; i++) {
        language = nav[browserLanguagePropertyKeys[i]];
        if (language && language.length) {
            return language;
        }
    }

    return null;
};

var app = angular.module( "oecdInstruments",
                          [ "ui.router",
                              "ui.router.metatags",
                              "officeuifabric.core",
                              "officeuifabric.components",
                              "pascalprecht.translate",
                              "angular-click-outside",
                              "ngSanitize",
                              "ngWig",
                              "ngFileUpload",
                              "tc.chartjs",
                              "isteven-multi-select" ] );

////for listening to changes to file input fields
//app.directive("filesInput", function() {
//    return {
//        require: "ngModel",
//        link: function postLink(scope,elem,attrs,ngModel) {
//            elem.on("change", function(e) {
//                var files = elem[0].files;
//                ngModel.$setViewValue(files);
//            });
//        }
//    };
//} );
//

var env = {};


// Import variables if present (from env.js)
if( window ) {
    // TODO : Maybe Object.assign not compatible with IE
    //Object.assign( env, window.__env );
    env = window.__env;
}


app.constant( "API_BASE_URL", env.apiBaseUrl );
app.constant( "SSO_LOGIN_URL", env.ssoLoginUrl );

app.config( function( $stateProvider, $urlRouterProvider, $locationProvider, $httpProvider ) {

    // Routes
    $locationProvider.html5Mode( true ).hashPrefix( "!" );
    var lang = getFirstBrowserLanguage();
    if( lang ) {
        lang = lang.substring( 0, 2 ).toLowerCase();
    }
    if( lang !== "en" && lang !== "fr" ) {
        lang = "en";
    }

    $urlRouterProvider.when( "/", function($location, $window, $state) {
      var queryStringParams = $location.search();
      if (queryStringParams.returnUrl) {
        $window.location = decodeURIComponent(queryStringParams.returnUrl);
      }

      else
        $state.transitionTo("app.home", { lang: lang });
    });

    $stateProvider
        .state( "app", {
            abstract: true,
            url: "/{lang}",
            template: "</ui-view>",
            "views": {
                "content": { templateUrl: "" }
            }
        } )
        .state( "app.home", {
            url: "/",
            "views": {
                "header": { templateUrl: "templates/partials/header.html" },
                "content": {
                    templateUrl: "templates/pages/home.html",
                    controller: "HomeCtrl"
                },
                "footer": { templateUrl: "templates/partials/footer.html" }
            }
        } )
        .state( "app.adherences", {
            url: "/adherences?adherentIds",
            "views": {
                "header": { templateUrl: "templates/partials/header.html" },
                "content": {
                    templateUrl: "templates/adherences/list.html",
                    controller: "AdherenceListCtrl"
                },
                "footer": { templateUrl: "templates/partials/footer.html" }
            }
        } )
        .state( "app.instruments", {
            url: "/instruments?mode&reference&term&searchScope&searchType&typeIds&committeeIds&themeIds&statusIds&&adherentIds&yearFrom&yearTo&dateType",
            "views": {
                "header": { templateUrl: "templates/partials/header.html" },
                "content": {
                    templateUrl: "templates/instruments/list.html",
                    controller: "InstrumentListCtrl"
                },
                "footer": { templateUrl: "templates/partials/footer.html" }
            }
        } )
        .state( "app.instrumentCreate", {
            url: "/instruments/create",
            "views": {
                "header": { templateUrl: "templates/partials/header.html" },
                "content": {
                    templateUrl: "templates/instruments/edit.html",
                    controller: "InstrumentEditCtrl"
                },
                "footer": { templateUrl: "templates/partials/footer.html" }
            }
        } )
        .state( "app.instrumentEdit", {
            url: "/instruments/{id}/edit",
            "views": {
                "header": { templateUrl: "templates/partials/header.html" },
                "content": {
                    templateUrl: "templates/instruments/edit.html",
                    controller: "InstrumentEditCtrl"
                },
                "footer": { templateUrl: "templates/partials/footer.html" }
            }
        } )
        .state( "app.instrumentShow", {
            url: "/instruments/{id}?preview",
            "views": {
                "header": { templateUrl: "templates/partials/header.html" },
                "content": {
                    templateUrl: "templates/instruments/show.html",
                    controller: "InstrumentDetailCtrl"
                },
                "footer": { templateUrl: "templates/partials/footer.html" }
            }
        } )
        .state( "app.settings", {
            url: "/settings",
            "views": {
                "header": { templateUrl: "templates/partials/header.html" },
                "content": {
                    templateUrl: "templates/pages/settings.html",
                    controller: "SettingsCtrl"
                },
                "footer": { templateUrl: "templates/partials/footer.html" }
            }
        } )
        .state( "app.stats", {
            url: "/stats",
            "views": {
                "header": { templateUrl: "templates/partials/header.html" },
                "content": {
                    templateUrl: "templates/pages/stats.html",
                    controller: "StatsCtrl"
                },
                "footer": { templateUrl: "templates/partials/footer.html" }
            }
        } )
        .state( "app.generalInformation", {
            url: "/about",
            "views": {
                "header": { templateUrl: "templates/partials/header.html" },
                "content": {
                    templateUrl: "templates/pages/general-information.html"
                },
                "footer": { templateUrl: "templates/partials/footer.html" }
            }
        } )
        .state( "app.unsubscribe", {
            url: "/unsubscribe",
            "views": {
                "header": { templateUrl: "templates/partials/header.html" },
                "content": {
                    templateUrl: "templates/pages/unsubscribed.html",
                    controller: "UnsubscribeCtrl"
                },
                "footer": { templateUrl: "templates/partials/footer.html" }
            }
        } )
        .state( "app.403", {
            url: "/403",
            "views": {
                "header": { templateUrl: "templates/partials/header.html" },
                "content": {
                    templateUrl: "templates/errors/403.html"
                },
                "footer": { templateUrl: "templates/partials/footer.html" }
            }
        } )
        .state( "app.404", {
            url: "/404",
            "views": {
                "header": { templateUrl: "templates/partials/header.html" },
                "content": {
                    templateUrl: "templates/errors/404.html"
                },
                "footer": { templateUrl: "templates/partials/footer.html" }
            }
        } );

    // For any unmatched url, send to 404
    $urlRouterProvider.otherwise( "/" + lang + "/404" );

    // ensure that PATCH requests have a correct header
    $httpProvider.defaults.headers.patch = {
        "Content-Type": "application/json;charset=utf-8"
    };

} );


app.run( function( $rootScope, $state, $stateParams, $translate, $window, $location, AuthService, API_BASE_URL ) {

    var user = {
        id: '0',
        privileges: [],
        subscribed: false,
        name: "anonymous",
        zone: "internet",
        canDo: function( privilege ) {
            if( this.zone == "internet" ) {
                return false;
            }
            return this.privileges.indexOf( privilege ) >= 0;
        }
    };

    $rootScope.API_BASE_URL = API_BASE_URL;
    $rootScope.currentDate = new Date();
    $rootScope.anonymous = user;
    $rootScope.user = user;
    $rootScope._ = {
        some: _.some,
    };

    AuthService.login().then( function( usr ) {
        var isAnon = usr.name === "anonymous";
        var isIntra = usr.zone === "intranet";

        if(isAnon && isIntra) AuthService.ssoLogin();
        if(isAnon) return false;

        usr.canDo = $rootScope.anonymous.canDo;

        angular.copy( usr, $rootScope.user );
    } );

    $rootScope.$on( "$stateChangeSuccess", function( event, toState, toParams ) {

        $rootScope.$state = toState;
        $rootScope.$params = toParams;

        document.body.scrollTop = document.documentElement.scrollTop = 0;

        if( $stateParams.lang !== undefined ) {
            $rootScope.lang = $stateParams.lang;
            $translate.use( $stateParams.lang );
        } else {
            $rootScope.lang = "en";
            $translate.use( $rootScope.lang );
        }

        // To adapt to Objects as we receive them for the server, we have use a "langIndex" to get the right text :(
        $rootScope.langIndex = $rootScope.lang === "fr" ? 1 : 0;

        $translate.filterDropdown = {
            en: {
                selectAll: "Select all",
                selectNone: "Clear",
                reset: "Reset",
                search: "Search",
                nothingSelected:
                    "All"
            },

            fr: {
                selectAll: "Tout sélectionner",
                selectNone: "Enlever",
                reset: "Réinitialiser",
                search: "Rechercher",
                nothingSelected: "Tous"
            }
        };

        $translate.filterDropdownYear = {
            en: {
                selectAll: "Select all",
                selectNone: "Clear",
                reset: "Reset",
                search: "Search",
                nothingSelected:
                    ""
            },

            fr: {
                selectAll: "Tout sélectionner",
                selectNone: "Enlever",
                reset: "Réinitialiser",
                search: "Rechercher",
                nothingSelected: ""
            }
        };


        $translate.filterDropdownEdit = {
            en: {
                selectAll: "Select all",
                selectNone: "Clear",
                reset: "Reset",
                search: "Search",
                nothingSelected: "Click here to select"
            },

            fr: {
                selectAll: "Tout sélectionner",
                selectNone: "Enlever",
                reset: "Réinitialiser",
                search: "Rechercher",
                nothingSelected: "Cliquez ici pour sélectionner"
            }
        };

        $rootScope.dropdownTexts = $translate.filterDropdown[ $rootScope.lang ];
        $rootScope.dropdownTextsYear = $translate.filterDropdownYear[ $rootScope.lang ];
        $rootScope.dropdownTextsEdit = $translate.filterDropdownEdit[ $rootScope.lang ];

        $rootScope.showInstrument = function( id, key, lang, target, preview ) {
            var params = {
                id: key.replaceAll( "/", "-" ),
                lang: lang,
                preview: preview
            };
            if( target === "blank" ) {
                var url = $state.href( "app.instrumentShow", params );
                $window.open( url, "_blank" );
            } else if( target === "preview" ) {
                var url = $state.href( "app.instrumentShow", params );
                $window.open( url, target, "width=600,height=800,left=200,top=50" );
            } else {
                $state.go( "app.instrumentShow", params );
            }
        };

        window.dataLayer.push({
            'event': 'page_view',
            'page_current_path' : window.location.href,
            'is_global': 'yes',
            'is_directorates': 'no',
            'is_satellite': 'yes'
        })

        $rootScope.editInstrument = function( instrumentId ) {
            $state.go( "app.instrumentEdit",
                       {
                           id: instrumentId,
                           lang: $rootScope.lang
                       } );
        };

    } );

} );
