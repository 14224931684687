app.service('AuthService', function (API_BASE_URL, SSO_LOGIN_URL, $http, $window) {
  this.login = function () {
    return $http.get(API_BASE_URL + 'user?t=' + Date.now()).then(function (results) {
      return results.data;
    });
  };

  this.logout = function () {
    return $http.post(API_BASE_URL + 'logout').then(function (results) {
      return results.data;
    });
  };

  this.ssoLogin = function () {
    var isWithQparams = SSO_LOGIN_URL.indexOf('?') > -1;
    var returnUrlPart = (isWithQparams ? '&' : '?') + 'returnUrl=' + encodeURIComponent(encodeURIComponent($window.location));
    $window.location = SSO_LOGIN_URL + returnUrlPart;
  };

});
