function dateToYMD( date ) {
    if( date === undefined || date === null ) {
        return '';
    }
    var d = date.getDate();
    var m = date.getMonth() + 1;
    var y = date.getFullYear();
    return "" + y + "-" + (m <= 9 ? "0" + m : m) + "-" + (d <= 9 ? "0" + d : d);
}

function countrySort( a, b ) {
    if( a.country.sortKey > b.country.sortKey ) {
        return 1;
    }
    if( a.country.sortKey < b.country.sortKey ) {
        return -1;
    }
    var cn = a.country.name.localeCompare( b.country.name );
    if( cn !== 0 ) {
        return cn;
    }
    return dateSort( a, b );
    
}

function dateSort( a, b ) {
    if( a.date && b.date ) {
        if( a.date > b.date ) {
            return 1;
        }
        if( a.date < b.date ) {
            return -1;
        }
        return 0;
    } else if( a.date ) {
        return 1;
    } else if( b.date ) {
        return -1;
    }
    return 0;
}

function datesAndReferencesSort(value) {
    var TYPES = {
        ADOPTED_ON: 1,
        MONITORED_IN: 9,
    };

    if (value && angular.isArray(value)) {
        var orderedByDate = _.orderBy(value, ['date']);
        var groupedByAdoptedAndMonitored = _.reduce(orderedByDate, function (agg, item) {
            if (item.type.id === TYPES.MONITORED_IN) {
                var itemAdoptedOn = _.find(orderedByDate, function (x) {
                    return x.type.id === TYPES.ADOPTED_ON &&
                      x.date && item.date &&
                      x.date.getFullYear() === item.date.getFullYear();
                });
                if (itemAdoptedOn) {
                    itemAdoptedOn.monitoredIns = itemAdoptedOn.monitoredIns || [];
                    itemAdoptedOn.monitoredIns.push(item)
                } else {
                    agg.push(item);
                }
            } else {
                agg.push(item);
            }
            return agg;
        }, []);

        var ordered = _.reduce(groupedByAdoptedAndMonitored, function (agg, item) {
            if (item.monitoredIns) {
                agg.push.apply(agg, [item].concat(item.monitoredIns));
                delete item.monitoredIns;
            } else {
                agg.push(item);
            }
            return agg;
        }, []);

        return ordered;
    }
    return value;
}

function adherenceRecordSort( a, b ) {
    if( a.date && b.date ) {
        if( a.date > b.date ) {
            return 1;
        }
        if( a.date < b.date ) {
            return -1;
        }
    }

    if( a.date ) {
        return 1;
    }

    if( b.date ) {
        return -1;
    }

    if( a.status && b.status ) {
        if( a.status.id > b.status.id ) {
            return 1;
        }
        if( a.status.id < b.status.id ) {
            return -1;
        }
    }

    return 0;
}

function adherenceStatusTransition( stat, adherence  ) {

    if( adherence.status.id  === 1 ) {
        stat.push( 1 );
    } else  if( adherence.status.id  === 2 ) {
        stat.push( 2 );
    } else if( adherence.status.id  === 3 ) {
        stat.push( 3 );
    } else if( adherence.status.id  === 4 ) {
        if( stat.length > 0 && stat[ stat.length - 1 ] == 3 ) {
            stat.pop( );
        }
    } else if( adherence.status.id  === 6 ) {
        stat.push( 4 );
    } else if( adherence.status.id  === 7 ) {
        stat.push( 5 );
    }
}

function getAdherenceStatus( adherence ) {
    var res = [ ];
    if(adherence.country.kind === "Member" ) {
        res.push( 1 );
    } else {
        res.push( 5 );
    }
    
    adherenceStatusTransition( res, adherence );
    angular.forEach( adherence.attachments, function( adh ) {
        adherenceStatusTransition( res, adh );
    } );
    
    if( res.length > 0 ) {
        return res.pop();
    }
    return adherence.country.kind === "Member" ? 1 : 5;
}
function shouldShowAsterisk( item ) {
    if( item.attachments.length > 0 && item.country.kind == "Member" ){
        return true;
    }

    if(!_.isEmpty(item.documentRef)) return true;

    if( item.showAsterisk === false ) {
        return false;
    }
    if( item.country.kind === "Member" ) {
        if( item.adherenceStatus == 2 || item.status.id == 2 || item.status.id == 5 )  {
            return true;
        }
    } else {
        if( item.adherenceStatus == 3
            || item.status.id >= 3
            || ( item.attachments && item.attachments.length > 0 )
            || ( item.documentRef && item.documentRef.length > 0 ) ) {
            return true;
        }
    }


    for( ii = 0; ii < item.comments.text.length; ii++ ) {
        if( ( item.comments.text[ ii ] ).value ) {
            return true;
        }
    }

    for( ii = 0; ii< item.attachments.length; ii++ ){
        var el = item.attachments[ ii ];
        if( ( el.comments.text.length >= 0 && ( el.comments.text[ 0 ] ).value || el.comments.text[ el.comments.text.length - 1 ].value ) ) {
            return true;
        }
    }
    return false;
};


function categorizeAdherence( instrument, adhl ) {
    adhl.sort( adherenceRecordSort );

    var item = adhl[ 0 ];
    if (adhl.length > 1) {
        var mostRecentitem = adhl[ adhl.length -1 ]
    }
    var cou = item.country;

    if( cou ) {
        //push other records as attachments to this, if there is any
        item.statuses = [];
        item.attachments = [];
        for( jj = 1; jj < adhl.length; jj++ ) {
            var ott = adhl[ jj ];
            item.attachments.push( ott );
            item.statuses.push( ott.selectedStatus );
        }

        if ( mostRecentitem ) {
            item.adherenceStatus = mostRecentitem.status.id
        }
        else {
            item.adherenceStatus = getAdherenceStatus( item );
        }

        item.showStrikeThru = (((item.adherenceStatus == 3 || item.adherenceStatus >= 6) && item.adherenceStatus !== 8)|| (item.adherenceStatus !==4 && item.selectedStatus ==3));

        item.specificShowAsterik = false;
        for(i = 0; i < item.statuses.length; i++) {
            if (item.statuses[i] == 5) item.specificShowAsterik = true;
        };

        if (item.adherenceStatus !== 6) {
            item.showAsterisk = item.showStrikeThru || shouldShowAsterisk( item );
        }

        if( cou.kind === "Member" ) {
            if (item.attachments.length > 0) {
            }
            if(( item.adherenceStatus <= 5 ||  item.adherenceStatus == 8) && item.selectedStatus != 7) {
                instrument.adherence.memberCountriesForView.push( item );
            }

            if( item.adherenceStatus != 1 || adhl.length > 1 || item.showAsterisk || item.showStrikeThru ) {
                instrument.adherence.memberExceptionals.push.apply( instrument.adherence.memberExceptionals, adhl );
            } else {
                //regular member state record
                instrument.adherence.memberCountries.push( item );
            }
        } else if( cou.kind === "Non-member" ) {
            instrument.adherence.nonMemberCountriesForView.push( item );

            var ott;
            for( jj = 0; jj < adhl.length; jj += 1 ) {
                ott = adhl[ jj ];
                instrument.adherence.nonMemberCountries.push( ott );
            }
        } else {
            instrument.adherence.othersForView.push( item );

            var ott;
            for( jj = 0; jj < adhl.length; jj += 1 ) {
                ott = adhl[ jj ];
                instrument.adherence.others.push( ott );
            }
        }
    } else {
        instrument.adherence.untouched.push.apply( instrument.adherence.untouched, adhl );
    }
}

function seggregateAdherenceInfo( instrument ) {
    instrument.adherence.memberCountries = [];
    instrument.adherence.memberExceptionals = [];
    instrument.adherence.nonMemberCountries = [];
    instrument.adherence.others = [];
    instrument.adherence.memberCountriesForView = [];
    instrument.adherence.nonMemberCountriesForView = [];
    instrument.adherence.othersForView = [];
    instrument.adherence.untouched = []; //this is to keep unaccounted records by the UI intact after save

    var adl = instrument.adherence.item.groupBy( function( ar ) {
        if( ar.country ) {
            return ar.country.id;
        }
        return -1;
    } );

    for( cl in adl ) {
        categorizeAdherence( instrument, adl[ cl ] );
    }
    
    instrument.adherence.memberCountriesForView.sort( countrySort );
    instrument.adherence.nonMemberCountriesForView.sort( countrySort );
    instrument.adherence.othersForView.sort( countrySort );

    instrument.adherence.memberCountries.sort( countrySort );
    instrument.adherence.memberExceptionals.sort( countrySort );
    instrument.adherence.nonMemberCountries.sort( countrySort );
    instrument.adherence.others.sort( countrySort );

    return instrument;
}

function setDefault( value, defValue ) {
    return ( value !== undefined ) ? value : defValue;
}


app.service( "MarkLogicApiAdapter", function( $sce, $filter, $sanitize, CommitteeService, HelperService, ListService ) {
    /*
     * This Adapter stood for having a proper code that doesn't include any back-end format or logic. Having an adapter
     * is in my opinion the best thing to do as we receive data Objects with a structure based on server-side
     * stuff (not RESTful and not JSON/JS/Client-side friendly). But at the end the work to do
     * in this adapter was too much for the delays, so we are using ugly code everywhere, sorry for that :(
     */
    
    /*
     * Instrument
     */
    this.formatInstrument = function( rawInstrument ) {
        
        var instrument = rawInstrument;
        
        return ListService.list().then( function( lists ) {


            instrument.isNew = !(instrument.statusSummary && instrument.statusSummary.lastPublishDate);

            //this is needed to fetch names from the server
            instrument.type = HelperService.findListItem( lists.instrumentTypes, instrument.type.id );
            instrument.status = HelperService.findListItem( lists.instrumentStatusTypes, instrument.status.id );
    
            if( !( instrument.committees ) ) {
                instrument.committees = {};
            }
            if( !( instrument.committees.parentCommittee) ) {
                instrument.committees.parentCommittee = [];
            }
            if( !( instrument.committees.involvedCommittee) ) {
                instrument.committees.involvedCommittee = [];
            }
            
            
            angular.forEach( instrument.committees.parentCommittee, function( committee, idx )  {
                instrument.committees.parentCommittee[ idx ] = HelperService.findListItem( lists.committees, committee.id, true );
            } );
    
            angular.forEach( instrument.committees.involvedCommittee, function( committee, idx )  {
                instrument.committees.involvedCommittee[ idx ] = HelperService.findListItem( lists.committees, committee.id, true );
            } );
            
            if( !( instrument.themes ) ) {
                instrument.themes = {};
            }
            if( !( instrument.themes.theme ) ) {
                instrument.themes.theme = [];
            }
    
            angular.forEach( instrument.themes.theme, function( theme, idx )  {
                instrument.themes.theme[ idx ] = HelperService.findListItem( lists.themes, theme.id, true );
            } );
            
            if( !(instrument.relations ) ) {
                instrument.relations = {};
            }
            
            if( !(instrument.relations.replacedBy ) ) {
                instrument.relations.replacedBy = [];
            }
            angular.forEach( instrument.relations.replacedBy, function( replacedBy )  {
                if( !replacedBy.date ) {
                    replacedBy.date = null;
                } else {
                    replacedBy.date = new Date( replacedBy.date );
                }
            } );
            
            
            if( !(instrument.relations.relatedTo ) ) {
                instrument.relations.relatedTo = [];
            }
            
            if( !(instrument.relations.replaces ) ) {
                instrument.relations.replaces = [];
            }
            
            if( !(instrument.comments) ) {
                instrument.comments = { lang: "en", value: "" };
            } else if(!_.isEmpty(_.get(instrument, 'comments.value'))) {
                instrument.comments.value = fixDownloadLinks(instrument.comments.value, true);
            }
            
            if( !(instrument.monitoring) ) {
                instrument.monitoring = {};
            }
            
            if( !(instrument.monitoring.entry) ) {
                instrument.monitoring.entry = [{
                    comments: {
                        text: [{lang: "en", value:""}, {lang: "fr", value:""} ]
                    }
                }];
            }

            // TODO: REFACTOR AFTER MONITORING MIGRATION
            angular.forEach( instrument.monitoring.entry, function( entry ) {
                if( !( entry.OLISRef ) ) {
                    entry.OLISRef = [];
                }

                if( !( entry.ref ) ) {
                    entry.ref = [ { lang: "en", uri: "", format: "file" }, { lang: "fr", uri: "", format: "file" } ];
                }

                if( !(entry.comments) ) {
                    entry.comments = {};
                }
                if( !(entry.comments.text) ) {
                    entry.comments.text = [{lang: "en", value:""}, {lang: "fr", value:""} ];
                }

                angular.forEach( entry.ref, function( ref ) {
                    if(!ref.desc ) {
                        ref.desc =  [ {lang: "en", value: "" }, {lang: "fr", value: "" } ];
                    }

                    //below is for showing existing records before comments were introduced into the entry element
                    angular.forEach( ref.narrative, function( narr, idx ) {
                        if( narr.value && idx <= 1 ) {
                            entry.comments[ idx ].value = narr.value;
                        }
                    } );
                } );

                angular.forEach( entry.comments.text, function( txt ) {
                    if( txt.value) {
                        var $div = angular.element("<div></div>");
                        $div.append(txt.value);
                        angular.forEach($div.find('a'), function(link) {
                            var linkHref = link.getAttribute('href');
                            if(linkHref && linkHref.indexOf('/api/download') > -1 && _.endsWith(linkHref, '.pdf')) {
                                link.target = '_blank';
                            }
                        });
                        txt.value = $sce.trustAsHtml( $div.html() );
                    }
                } );


                entry.OLISRefEditor = entry.OLISRef.map( function( ref ) { return ref.uri } ).join( ", " );

                if (!(entry.clause))
                {
                    entry.clause = {};
                }

                if( (entry.clause.id) ) //Clause became optional
                {
                    entry.clause = HelperService.findListItem( lists.monitoringTypes, entry.clause.id );
                    entry.selectedClause = '' + entry.clause.id;
                }

            } );
            
            if( !(instrument.changeHistory) ) {
                instrument.changeHistory = {};
            }
            
            if( !(instrument.changeHistory.update) ) {
                instrument.changeHistory.update = [];
            }
            
            angular.forEach( instrument.changeHistory.update, function( update ) {
                if( update.date ) {
                    update.date = new Date( update.date );
                }
                
                if( !(update.OLISRef ) ) {
                    update.OLISRef = [];
                }
                if( !(update.minutesOLISRef ) ) {
                    update.minutesOLISRef = [];
                }
                update.OLISRefEditor = update.OLISRef.map( function( ref ) { return ref.uri } ).join( ", " );
                update.minutesOLISRefEditor = update.minutesOLISRef.map( function( ref ) { return ref.uri } ).join( ", " );
                
                update.type = HelperService.findListItem( lists.instrumentStatusUpdateTypes, update.type.id );
                update.historyUpdateType = update.type.id.toString();
                
            } );
            instrument.changeHistory.update = datesAndReferencesSort(instrument.changeHistory.update);
            
            
            if( !(instrument.adherence) ) {
                instrument.adherence = {};
            }
            if( !( instrument.adherence.intro ) ) {
                instrument.adherence.intro = {};
            }
            if( !( instrument.adherence.intro.text ) ) {
                instrument.adherence.intro.text = [ { lang:"en", value:""}, { lang:"fr", value:""}];
            }

            angular.forEach( instrument.adherence.intro.text, function( adherenceText ) {
                adherenceText.value = $sce.trustAsHtml(fixDownloadLinks(adherenceText.value));
            } );


            if( !( instrument.adherence.item ) ) {
                instrument.adherence.item = [];
            }
            
            angular.forEach( instrument.adherence.item, function( item, index ) {
                item.status = HelperService.findListItem( lists.adherenceUpdateTypes, item.status.id );
                item.country = HelperService.findListItem( lists.countries, item.country.id );

                if( item.status ) {
                    item.selectedStatus = item.status.id.toString();
                }
                if( item.country ) {
                    item.selectedCountry = item.country.id.toString();
                }
                
                // Format dates
                if( item.date ) {
                    item.date = new Date( item.date );
                }
                // We attach its original index to Adherences for validation errors identification purposes
                item.index = index;
                
                if( !(item.comments) ) {
                    item.comments = {};
                }
                if( !(item.comments.text) ) {
                    item.comments.text = [{lang: "en", value:""}, {lang: "fr", value:""} ];
                }
                angular.forEach( item.comments.text, function( comment ) {
                    comment.excerpt = $sce.trustAsHtml( $filter( "truncate" )( comment.value, true, 100 ) );
                    comment.value = $sce.trustAsHtml(fixDownloadLinks(comment.value));
                } );

                if( !( item.documentRef ) ) {
                    item.documentRef = [];
                }

                angular.forEach( item.documentRef, function( ref ) {
                    if( !( ref.desc ) ) {
                        ref.desc = [ { lang:"en", value:""}, { lang:"fr", value:""}];
                    } else if( !ref.desc.length ) {
                        ref.desc = [ ref.desc ];
                    }
                } );
            } );
            seggregateAdherenceInfo( instrument );
    
            angular.forEach(instrument.adherence.memberCountriesForView, function( adherence )  {
                adherence.adherenceStatus = HelperService.findListItem( lists.adherenceTypes, adherence.adherenceStatus );
            } );

            angular.forEach(instrument.adherence.nonMemberCountriesForView, function( adherence )  {
                adherence.adherenceStatus = HelperService.findListItem( lists.adherenceTypes, adherence.adherenceStatus );
            } );

            angular.forEach(instrument.adherence.othersForView, function( adherence )  {
                adherence.adherenceStatus = HelperService.findListItem( lists.adherenceTypes, adherence.adherenceStatus );
            } );


            // trust as HTML
            if( !(instrument.background ) ) {
                instrument.background = {};
            }
            if( !(instrument.background.text ) ) {
                instrument.background.text = [{lang: "en", value:""}, {lang: "fr", value:""} ];
            }
            
            angular.forEach( instrument.background.text, function( backgroundText ) {
                if( backgroundText.value ) {
                    backgroundText.value = $sce.trustAsHtml(fixDownloadLinks(backgroundText.value));
                }
            } );
    
            if( !(instrument.blurb ) ) {
                instrument.blurb = {};
            }
            if( !(instrument.blurb.text ) ) {
                instrument.blurb.text = [{lang: "en", value:""}, {lang: "fr", value:""} ];
            }
    
            angular.forEach( instrument.blurb.text, function( blurbText ) {
                if( blurbText.value ) {
                    blurbText.value = $sce.trustAsHtml(fixDownloadLinks(blurbText.value));
                }
            } );
    
    
            if( !( instrument.bodyText ) ) {
                instrument.bodyText = {};
            }

            if( !( instrument.bodyText.intro ) ) {
                instrument.bodyText.intro = {};
            }
    
            if( !( instrument.bodyText.intro.text ) ) {
                instrument.bodyText.intro.text = [ { lang:"en", value:""  }, { lang:"fr", value: "" } ];
            }
            angular.forEach(instrument.bodyText.intro.text, function( text ) {
                 if( text.value ){
                     text.value = $sce.trustAsHtml( fixDownloadLinks(text.value) );
                 } else {
                     text.value = "";
                 }
            } );

            if( !( instrument.bodyText.brochureDisclaimer ) ) {
                instrument.bodyText.brochureDisclaimer = {};
            }

            if( !( instrument.bodyText.brochureDisclaimer.text ) ) {
                instrument.bodyText.brochureDisclaimer.text = [ { lang:"en", value: ""  }, { lang:"fr", value: "" } ];
            }
            angular.forEach(instrument.bodyText.brochureDisclaimer.text, function( text ) {
                if( text.value ){
                    text.value = $sce.trustAsHtml( text.value );
                } else {
                    text.value = "";
                }
            } );
            
            
            if( !( instrument.bodyText.ref ) ) {
                instrument.bodyText.ref = [ { lang:"en"  }, { lang:"fr" } ];
            }

            if( !( instrument.bodyText.orig ) ) {
                instrument.bodyText.orig = [ { lang:"en"  }, { lang:"fr" } ];
            }


            if( !( instrument.bodyText.support ) ) {
                instrument.bodyText.support = [ ];
            }

            angular.forEach( instrument.bodyText.support, function( ref ) {
                if( !( ref.narrative ) ) {
                    ref.narrative = [ { lang:"en", value:""}, { lang:"fr", value:""}];
                } else  {
                    angular.forEach( ref.narrative, function( narr ) {
                        narr.value = $sce.trustAsHtml( narr.value );
                    } );
                }
                if( !( ref.desc ) ) {
                    ref.desc = [ { lang:"en", value:""}, { lang:"fr", value:""}];
                }
            } );

            if( !(instrument.coverImage ) ) {
                instrument.coverImage = { uri: "", format: "image", desc: [ { lang:"en", value:""}, { lang:"fr", value:""}] }
            }

            if( !instrument.coverImage.desc ) {
                instrument.coverImage.desc = [ { lang:"en", value:""}, { lang:"fr", value:""} ];
            }

            if( !(instrument.notes ) ) {
                instrument.notes = { lang: "en", value:"" };
            }
            if( !(instrument.notes.value ) ) {
                instrument.notes.value = "";
            }
            else {
                instrument.notes.value = $sce.trustAsHtml( instrument.notes.value );
            }

            var nextManuallyUploadedBooklet = {};
            if(instrument.manuallyUploadedBooklet) {
                angular.forEach(instrument.manuallyUploadedBooklet, function(x) {
                    nextManuallyUploadedBooklet[x.lang] = x;
                });
            }
            instrument.manuallyUploadedBooklet = nextManuallyUploadedBooklet;

            if(instrument.translations && instrument.translations.translation) {
                instrument.translations.translation = instrument.translations.translation.reduce(function(agg, tran) {
                    agg.push(angular.extend({ file: { uri: tran.uri, name: tran.filename || tran.uri } }, tran));
                    return agg;
                }, []);
            }

            if (instrument.hostedDocuments) {
                instrument.hostedDocuments = instrument.hostedDocuments.reduce(function (agg, hDoc) {
                    agg.push({
                        file: {
                            uri: hDoc.uri,
                            name: (hDoc.desc && hDoc.desc.length > 0) ? hDoc.desc[0].value : '',
                            format: hDoc.format
                        },
                        narrative: (hDoc.narrative && hDoc.narrative.length > 0) ? hDoc.narrative[0].value : ''
                    });
                    return agg;
                }, []);
            }

            return [ lists, instrument ];
        } );
    };
    
    this.unformatInstrument = function( instrument ) {
        var res = angular.copy( instrument );
        delete res.isNew;
        
        res.status = { id: res.status.id };
        if( res.selectedStatus ) {
            //comes from editor
            res.status = { id: res.selectedStatus };
        }
        if( res.selectedType ) {
            res.type = { id: res.selectedType };
        }
        delete res.selectedStatus;
        delete res.selectedType;
        if( !res.changeHistory.update.length ) {
            delete res.changeHistory;
        } else {
            angular.forEach( res.changeHistory.update, function( update ) {
                if( update.date ) {
                    if( typeof update.date !== "string" ) {
                        update.date = dateToYMD( update.date );
                    }
                } else {
                    delete update.date;
                }
                update.type = { id: update.type.id };
                if( update.historyUpdateType ) {
                    update.type = { id: update.historyUpdateType };
                }
                delete update.historyUpdateType;
                
                if( update.OLISRefEditor && update.OLISRefEditor.trim() ) {
                    update.OLISRef = update.OLISRefEditor.split( "," ).map( function( item ) {
                        return { format: "OLISRef",
                            uri: item.trim() };
                    } );
                } else {
                    update.OLISRef = [];
                }
                delete update.OLISRefEditor;
                
                if( update.minutesOLISRefEditor && update.minutesOLISRefEditor.trim() ) {
                    update.minutesOLISRef = update.minutesOLISRefEditor.split( "," ).map( function( item ) {
                        return { format: "OLISRef",
                            uri: item.trim() };
                    } );
                } else {
                    update.minutesOLISRefEditor = [];
                }
                delete update.minutesOLISRefEditor;
            } );
        }

        //temp measure: for missing lang in the migrated text
        if( res.title.name.length > 1 ) {
            res.title.name[ 1 ].lang = "fr";
        }

        angular.forEach( res.background.text, function( backgroundText ) {
            if( backgroundText.value ) {
                backgroundText.value = $sanitize( backgroundText.value.toString() );
            }
        } );
    
        angular.forEach( res.blurb.text, function( blurbText ) {
            if( blurbText.value ) {
                blurbText.value = $sanitize( blurbText.value.toString() );
            }
        } );
        
        angular.forEach( res.adherence.intro.text, function( adherenceText ) {
            //unwrap trusted html
            if( adherenceText.value ) {
                adherenceText.value = $sanitize( adherenceText.value.toString() );
            }
        });


        res.adherence.item = [];
        res.adherence.item.push.apply( res.adherence.item, res.adherence.memberCountries );
        res.adherence.item.push.apply( res.adherence.item, res.adherence.memberExceptionals );
        res.adherence.item.push.apply( res.adherence.item, res.adherence.nonMemberCountries );
        res.adherence.item.push.apply( res.adherence.item, res.adherence.others );
        res.adherence.item.push.apply( res.adherence.item, res.adherence.untouched );
        delete res.adherence.memberCountries;
        delete res.adherence.memberExceptionals;
        delete res.adherence.nonMemberCountries;
        delete res.adherence.others;
        delete res.adherence.memberCountriesForView;
        delete res.adherence.nonMemberCountriesForView;
        delete res.adherence.othersForView;
        delete res.adherence.untouched;

        angular.forEach( res.adherence.item, function( adherence ) {
            if( !adherence.date ) {
                delete adherence.date
            } else if( typeof adherence.date !== "string" ) {
                adherence.date = dateToYMD( adherence.date );
            }

            adherence.status = { id: adherence.status.id };
            if( adherence.selectedStatus ) {
                adherence.status = { id: adherence.selectedStatus };
            }
            if( adherence.selectedCountry ) {
                adherence.country = { id: adherence.selectedCountry };
            } else if( adherence.country ) {
                adherence.country = { id: adherence.country.id };
            }

    
            //editor items
            delete adherence.popupOpen;
            delete adherence.index;
            delete adherence.showDocumentModal;
            delete adherence.selectedStatus;
            delete adherence.selectedCountry;
            delete adherence.attachments;
            delete adherence.adherenceStatus;
            delete adherence.showAsterisk;
            delete adherence.statuses;
            delete adherence.specificShowAsterik;
            delete adherence.showStrikeThru;
            
            if( adherence.comments && adherence.comments.text.length ) {
                angular.forEach( adherence.comments.text, function( text, idx ) {
                    delete text.excerpt;
                    if( text.value ) {
                        text.value = $sanitize( text.value.toString() );
                    }
                    text.lang = idx == 0 ? "en" : "fr";
                } );
            } else {
                delete adherence.comments;
            }
            if( adherence.documentRef && adherence.documentRef.length ) {
                adherence.documentRef = adherence.documentRef.filter( function( ref ) { return ref.uri && ref.uri.length > 0; } );
            } else {
                delete adherence.documentRef;
            }
        } );

        angular.forEach( res.themes.theme, function( th, idx ) {
            res.themes.theme[ idx ] = { id: th.id };
        } );
    
    
        angular.forEach( res.committees.parentCommittee, function( committee, idx ) {
            res.committees.parentCommittee[ idx ] = { id: committee.id };
        } );

        angular.forEach( res.committees.involvedCommittee, function( committee, idx ) {
            res.committees.involvedCommittee[ idx ] = { id: committee.id };
        } );

        if( res.relations.replacedBy.length ) {
            var date = res.relations.replacedBy[ 0 ].date;
            if( date && typeof date !== "string" ) {
                date = dateToYMD( date );
            }
            
            angular.forEach( res.relations.replacedBy, function( replacedBy ) {
                if( date ) {
                    replacedBy.date = date;
                } else {
                    delete replacedBy.date;
                }
                delete replacedBy.title;
                delete replacedBy.ticked;
            } );
        } else {
            delete res.relations.replacedBy;
        }
        delete res.relations.replacedByDate;

        if( res.relations.relatedTo.length ) {
            angular.forEach( res.relations.relatedTo, function( item ) {
                delete item.title;
                delete item.ticked;
            } );
        } else {
            delete res.relations.relatedTo;
        }

        // TODO: REFACTOR AFTER MONITORING MIGRATION
        if( res.monitoring.entry.length ) {
            angular.forEach( res.monitoring.entry, function( entry ) {
                if(entry.clause) entry.clause = {id: entry.clause.id};
                if (entry.selectedClause) {
                    entry.clause = {id: entry.selectedClause};
                }
                delete entry.selectedClause;

                if(!(entry.clause.id)) //Clause became optional, if no Id is defined then it should be removed to comply with Instrument schema
                {
                    delete entry.clause;
                }

                if(!(entry.year)) //Year became optional, if Year is not set then it should be removed to comply with Instrument schema
                {
                    delete entry.year;
                }

                if( entry.OLISRefEditor && entry.OLISRefEditor.trim() ) {
                    entry.OLISRef = entry.OLISRefEditor.split( "," ).map( function( item ) {
                        return { format: "OLISRef",
                                 uri: item.trim() };
                    } );
                } else {
                    entry.OLISRef = [];
                }
                delete entry.OLISRefEditor;

                if( entry.ref && entry.ref.length ) {
                    entry.ref = entry.ref.filter( function( ref ) { return ref.uri && ref.uri.length > 0; } );
                }

                angular.forEach( entry.comments.text, function( txt ) {
                    if( txt.value ) {
                        txt.value = $sanitize( txt.value.toString() );
                    }
                } );

                angular.forEach( entry.ref, function( ref ) {
                    if( ref.desc ) {
                        if( !ref.desc[ 0 ].value ) {
                            ref.desc[ 0 ].value = "Published version";
                        }
                        if( !ref.desc[ 1 ].value ) {
                            ref.desc[ 1 ].value = "Version publiée";
                        }
                    }
                } );
            } );
        } else {
            delete res.monitoring;
        }
    
        if( res.notes.value ) {
            res.notes.value = $sanitize( res.notes.value.toString() );
        } else {
            delete res.notes;
        }
        
        if( res.comments.value ) {
            res.comments.value = $sanitize( res.comments.value.toString() );
            res.comments.lang = "en";
        } else {
            delete res.comments;
        }
    
        angular.forEach( res.bodyText.intro.text, function( text )  {
            if( text.value ) {
                text.value = $sanitize( text.value.toString() );
            }
        } );


        res.bodyText.ref = res.bodyText.ref.filter( function( ref ) {  return ref.uri && ref.uri.length > 0;  } );
        res.bodyText.orig = res.bodyText.orig.filter( function( ref ) {  return ref.uri && ref.uri.length > 0;  } );
        res.bodyText.support = res.bodyText.support.filter( function( ref ) {  return ref.uri && ref.uri.length > 0;  } );

        angular.forEach( res.bodyText.support, function( ref ) {
            angular.forEach( ref.narrative, function( dsc ) {
                if( dsc.value ) {
                    dsc.value = $sanitize( dsc.value.toString() );
                }
            } );
        } );

        angular.forEach(res.bodyText.brochureDisclaimer.text, function( text ) {
            if( text.value ){
                text.value =  $sanitize( text.value.toString() );
            }
        } );



        delete res.bodyText.supportIntro; //temp. measure to delete obsolete property

        if( !res.adherence.intro.text.length ) {
            delete res.adherence.intro;
        }

        if( res.coverImage.uri.length == 0 ) {
            delete res.coverImage;
        }

        delete res.statusSummary;

        if(res.manuallyUploadedBooklet) {
            var nextManuallyUploadedBooklet = [];
            Object.keys(res.manuallyUploadedBooklet).forEach(function(key) {
              nextManuallyUploadedBooklet.push(res.manuallyUploadedBooklet[key]);
            });
            res.manuallyUploadedBooklet = nextManuallyUploadedBooklet;
        }

        if(res.translations && res.translations.translation) {
            res.translations.translation = res.translations.translation.reduce(function(agg, tran) {
                if(tran.file && tran.file.uri && tran.language) {
                 agg.push({ uri: tran.file.uri, language: tran.language, filename: tran.file.name });
                }
                return agg;
            }, []);
        }

        if (res.hostedDocuments) {
            res.hostedDocuments = res.hostedDocuments.reduce(function (agg, hDoc) {
                if (hDoc && hDoc.file && hDoc.file.uri) {
                    agg.push({
                        lang: 'en',
                        format: hDoc.file.format,
                        uri: hDoc.file.uri,
                        desc: [{ lang: 'en', value: hDoc.file.name }],
                        narrative: [{ lang: 'en', value: hDoc.narrative }]
                    })
                }
                return agg;
            }, []);
        }

        return res;
    };
    
    /*
     * Concepts
     */
    
    this.formatConcepts = function( rawConcepts ) {
        
        angular.forEach( rawConcepts, function( rawConcept ) {
            
            angular.forEach( rawConcept.list, function( rawElement ) {
                //country
                if( rawConcept.id === 1 ) {
                    if( rawElement.accessionDate ) {
                        rawElement.accessionDate = new Date( rawElement.accessionDate );
                    }
                    rawElement.isInAccessionProcess = setDefault( rawElement.isInAccessionProcess, "false" ) == "true";
                    rawElement.isKeyPartner = setDefault( rawElement.isKeyPartner, "false" ) == "true";
                    rawElement.hasCountryProgramme = setDefault( rawElement.hasCountryProgramme, "false" ) == "true";
                }
                rawElement.englishName = rawElement.name[ 0 ].value;
                rawElement.frenchName = rawElement.name[ 1 ].value;
                rawElement.englishSortOrder = rawElement.name[ 0 ].sortKey;
                rawElement.frenchSortOrder = rawElement.name[ 1 ].sortKey;
    
                rawElement.active = setDefault( rawElement.active, true );
            } );
            rawConcept.elements = rawConcept.list;
            rawConcept.name = rawConcept.label;
            delete rawConcept.list;
            delete rawConcept.label;
        } );
        return rawConcepts;
    };
    
    // Converts conventionally formatted "concept" Javascript objects into server side concepts
    this.unformatConcepts = function( niceConcepts ) {
        var res = [];
        
        angular.forEach( niceConcepts.list, function( uic ) {
            if( uic.modified ) {
                var concept = angular.copy( uic );
                delete concept.modified;

                angular.forEach( concept.elements, function( element ) {
                    element.name = [ {
                        lang: "en", value: element.englishName, sortKey: element.englishSortOrder
                    }, {
                        lang: "fr", value: element.frenchName, sortKey: element.frenchSortOrder
                    } ];

                    delete element.idEditable;
                    delete element.englishName;
                    delete element.englishSortOrder;
                    delete element.frenchName;
                    delete element.frenchSortOrder;

                    if( element.active ) {
                        delete element.active; // default is active
                    }
                    //country
                    if( concept.id === 1 ) {
                        if( element.accessionDate ) {
                            element.accessionDate = dateToYMD( element.accessionDate );
                        }
                        if( !element.isInAccessionProcess ) {
                            delete element.isInAccessionProcess;
                        }
                        if( !element.isKeyPartner ) {
                            delete element.isKeyPartner;
                        }
                        if( !element.hasCountryProgramme ) {
                            delete element.hasCountryProgramme;
                        }

                        if( element.kind != "Member" ) {
                            delete element.accessionDate;
                        } else {
                            delete element.hasCountryProgramme;
                            delete element.isKeyPartner;
                            delete element.isInAccessionProcess;
                        }
                    }
                    if( element.active ) {
                        //default value
                        delete element.active;
                    }
                } );
                concept.label = concept.name;
                concept.list = concept.elements;
                delete concept.name;
                delete concept.elements;
                res.push( concept );
            }
        } );
        
        return res;
    };
    
    /*
     * Instruments
     */
    
    // Converts formatted params into server side params
    this.unformatInstrumentParams = function( params ) {

        var serverSideParams = {};

        // string / int params
        if( params.term ) {
            serverSideParams.term = params.term;

            if( params.searchScope ) {
                serverSideParams.searchScope = params.searchScope;
            }
            // if( params.searchType ) {
            //     serverSideParams.searchType = params.searchType;
            // }
        }
        if( params.reference ) {
            var num = parseInt( params.reference )
            if( isNaN( num ) ) {
                serverSideParams.reference = params.reference;
            } else {
                var str = "0000" + num;
                serverSideParams.reference = 'OECD/LEGAL/' + str.substr( str.length - 4 )
            }
        }
        if( params.monitoringType ) {
            serverSideParams.monitoringType = params.monitoringType;
        }
        if( params.lang ) {
            serverSideParams.lang = params.lang;
        }
        
        // array params
        if( params.typeIds && params.typeIds.length ) {
            serverSideParams.typeIds = params.typeIds;
        }
        if( params.committeeIds && params.committeeIds.length ) {
            serverSideParams.committeeIds = params.committeeIds;
        }
        if( params.themeIds && params.themeIds.length ) {
            serverSideParams.themeIds = params.themeIds;
        }
        if( params.statusIds && params.statusIds.length ) {
            serverSideParams.statusIds = params.statusIds;
        }
        if( params.adherentIds && params.adherentIds.length ) {
            serverSideParams.adherentIds = params.adherentIds;
        }
    
        // date range params
        var range = "";
        if( params.dateType.length > 0 ) {
            var suffixs = "-01-01";
            var suffixe = "-12-31";

            // if (params.dateType === 'monitoring') {
            //     suffixs = "";
            //     suffixe = "";
            // }

            if (params.dateType === "lastPublished") {
                range = dateToYMD(params.dateFrom) + "," + dateToYMD(params.dateTo)
            } else {
                if (params.yearFrom.length) {
                    range += params.yearFrom[0].name + suffixs;
                }
                if (params.yearTo.length) {
                    range += "," + params.yearTo[0].name + suffixe;
                }
            }
            if (range) {
                serverSideParams[params.dateType + "DateRange"] = range;
            }
        }
        
        return serverSideParams;
    };
} );


function fixDownloadLinks(str, appendWindowOpen) {
    if (str) {
        try {
            var $str = angular.element("<div></div>").append(str);
            angular.forEach($str.find('a'), function (link) {
                if (link.getAttribute('href').indexOf('/api/download') > -1 && !link.getAttribute('target')) {
                    link.setAttribute('target', '_blank');

                    // #31842 - needed to fix overview comments on edit instrument page. so document can be viewed from wysiwyg editor
                    if(appendWindowOpen) {
                        var currentLocation = _.get(window, 'location.href');
                        if(_.endsWith(currentLocation, '/edit')) {
                            var wOpenTemplate = _.template('window.open("${aHref}")');
                            link.setAttribute('onclick', wOpenTemplate({aHref: link.getAttribute('href')}))
                        }
                    }
                }
            });
            return $str.html();
        } catch(err) {
            return str;
        }
    }
    return str;
}
