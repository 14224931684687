app.controller('PrintoutAdherencesCtrl', function ($window, $filter, $scope, PrintoutService, FlashMessageService) {

  var self = this;

  // simple JS print of selected Adherences
  self.printList = function (adherences) {
    if (!adherences.length) {
      FlashMessageService.show('You must select the adherences you want to print by clicking on the their checkbox.', 'warning');
    } else {
      $window.print();
    }
  };

  // Create a custom printout of Adherence Instruments from server and display it
  self.printInstrumentList = function (adherences) {

    if (!adherences.length) {
      return false;
    }

    var instrumentIds = [];

    angular.forEach(adherences, function (adherence) {
      instrumentIds.push(adherence.instrument.id);
    });

    PrintoutService.create(instrumentIds);

  };

  self.sendListByEmail = function (adherences) {

      if( !adherences.length ) {
          return false;
      }

      var instrumentsTable = '';
      var cou = ''
      angular.forEach( adherences, function( adherence ) {
          if( cou !== adherence.country.name ) {
              instrumentsTable += '\n\n' + adherence.country.name;
              cou = adherence.country.name;
          }
          instrumentsTable += '\n' + adherence.instrument.title
              + '\n' + adherence.instrument.key
              + '\n' + 'https://legalinstruments.oecd.org/' + $scope.lang + '/instruments/' + adherence.instrument.key.replaceAll( '/', '-' )
              + '\n'
      } );

      var subject = '';
      if( $scope.langIndex == 0 ) {
          instrumentsTable += '\n\nSource: Compendium of Legal Instruments of the OECD\nhttps://legalinstruments.oecd.org';
          subject = 'Selected adherences from the Compendium of Legal Instruments of the OECD';
      } else {
          instrumentsTable += '\n\nSource : Recueil des Instruments Juridiques de l’OCDE\nhttps://legalinstruments.oecd.org';
          subject = 'Sélection d\'Adhérents du Recueil d\'Instruments Juridiques de l\'OCDE';
      }

      self.sendEmail( subject, instrumentsTable );
  };

  self.sendEmail = function (subject, message) {
    message = message.replaceAll( "\n", "%0D%0A" );
    $window.open("mailto:?subject=" + subject + "&body=" + message, "_self");
  };

});
