app.service('HelperService', function( $http, $sce, $rootScope ) {

  // extract Ids from resources array and return string separated by commas
    this.extractIds = function( list ) {
        var idl =''
        for( var i = 0; i < list.length; i++ ) {
            idl += i > 0 ? (',' +list[ i ].id) : list[ i ].id
        }
        return idl
    }

    this.findListItem = function( list, srchId, nocopy ) {
        if( list.hashset ) {
            var res = list.hashset[ "" + srchId ];
            if( res ) {
                if( nocopy ) {
                    return res;
                }
                return angular.copy( res );
            }
        } else {
            for( var ii = 0; ii < list.length; ii++ ) {
                var item = list[ ii ]
                if( srchId === item.id ) {
                    if( nocopy ) {
                        return item;
                    }
                    return angular.copy( item );
                }
            }
        }
    }
    
    this.selectAndAttachNames = function( list, items ) {
        var ii = 0;
        for( ; ii < items.length; ii++ ) {
            var ci = items[ ii ]
            var li = this.findListItem( list, ci.id, true )
            if( li ) {
                li.ticked = true; //this is used for editor controls
            }
        }

        //now remove disabled items that are not ticked
        this.removeDisabledItems( list );
    };

    this.removeDisabledItems = function( list ) {
        ii = 0;
        while(  ii < list.length ) {
            var ci = list[ ii ];
            //if( !ci || (!ci.ticked && ci.active === false ) ) {
            if( (!ci.ticked && ci.active === false ) ) {
                list.splice( ii, 1 );
            } else {
                ii++;
            }
        }
    };

    this.selectAndAttachNamesWithIds = function( list, items ){
        var res = []
        if( typeof items === "string" ) {
            var idlist = items.split( "," );
            for( i = 0; i < idlist.length; i += 1 ) {
                var id = idlist[ i ];
                if( isNaN( id ) ) {
                    continue;
                }
                var li = this.findListItem( list, parseInt( id ), true )
                if( li ) {
                    li.ticked = true; //this is used for editor controls
                    res.push( li );
                }
            }
        }
        //now remove disabled items that are not ticked
        this.removeDisabledItems( list )

        return res;
    };

    this.inferSearchParamName = function( term ) {
        if (/\s*(([a-z\/]+\(((19)?[56789]\d|20?[0-9]{2}\d)\)[a-z\/\d]*)|(OECD\/LEGAL\/[0-9]{4})|[0-9]{1,4})/i.test( term ) ) {
            return "reference";
        }
        return "term";
    };

    this.buildOlisUrl = function( ref, lang ) {
        if( /\((19[5-8]\d|[5-8]\d)\)/.test( ref ) ) {
            //old ref, no links to ONE
            return undefined;
        }
        return $sce.trustAsUrl( "https://one.oecd.org/document/{ref}/{lang}/pdf".formatUnicorn( { ref: ref, lang: lang } ) );
    };

});
