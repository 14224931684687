// Fire function on pressing enter
app.directive("ngEnter", function () {
  return function (scope, element, attrs) {
    element.bind("keydown keypress", function (event) {
      if (event.which === 13) {
        scope.$apply(function () {
          scope.$eval(attrs.ngEnter);
        });

        event.preventDefault();
      }
    });
  };
});

app.filter("truncate", function () {
  return function (value, wordwise, max, showAll) {
    if (!value) {
      return "";
    }
    if (showAll) {
      return value;
    }

    max = parseInt(max, 10);
    if (!max) {
      return value;
    }
    if (value.length <= max) {
      return value;
    }

    value = value.substr(0, max);
    if (wordwise) {
      var lastspace = value.lastIndexOf(" ");
      if (lastspace !== -1) {
        value = value.substr(0, lastspace);
      }
    }

    return value + " …";
  };
});

app.directive("refAnchor", function () {
  return {
    restrict: 'C',
    link: function (scope, element, attrs) {
      element.on('click', 'a', function (e) {
        $location.hash(anchor);
        $anchorScroll();
      });
    }
  };
});

app.directive("scrollStick", function ($window) {
  return function (scope, element, attrs) {
    angular.element($window).bind("scroll", function () {
      if (this.pageYOffset > attrs.scrollStick) {
        if (!angular.element(element).hasClass('is-active')) {
          angular.element(element).addClass('is-active');
        }
      } else {
        if (angular.element(element).hasClass('is-active')) {
          angular.element(element).removeClass('is-active');
        }
      }
      scope.$apply();
    });
  };
});

app.directive('onFinishRender', function ($timeout) {
  return {
    restrict: 'A',
    link: function (scope, element, attr) {
      if (scope.$last === true) {
        $timeout(function () {
          scope.$emit('ngRepeatFinished');
        });
      }
    }
  }
});

app.directive('preventUrlChange', function () {
  return {
    restrict: 'A',
    link: function (scope, element, attrs) {
      $(element).on("click", function (event) {
        event.preventDefault();
      });
    }
  };
});

app.directive('dateAsYear', function () {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, element, attrs, ctrl) {
      ctrl.$parsers.push(
        function parseOutput(viewValue) {
          var isValidYear = viewValue && /^\d{4}$/.test(viewValue) && parseInt(viewValue, 10) > 1990;
          if (isValidYear) return new Date(viewValue, 0, 7);
          return null;
        }
      );
      ctrl.$formatters.push(
        function formatInput(modelValue) {
          if (angular.isDate(modelValue)) return modelValue.getFullYear();
          return modelValue;
        }
      );
    }
  }
});

app.directive('generalInfoEditor', function () {
  return {
    restrict: 'A',
    scope: {},
    controller: function ($rootScope, $sce, $timeout, GeneralInfoService, FlashMessageService, UploadService, CopyToClipboardService, API_BASE_URL) {
      var vm = this;
      vm.displayMode = 'read';

      activate();

      vm.generalInfoAsHtml = function() {
        return $sce.trustAsHtml(_.get(vm, 'generalInfo.text'));
      };

      vm.save = function () {
        GeneralInfoService
          .saveGeneralInfo(vm.generalInfoEdit)
          .then(function () {
            vm.generalInfo = vm.generalInfoEdit;
            FlashMessageService.show("About saved", "success");
            vm.displayMode = 'read';
          })
          .catch(function (err) {
            FlashMessageService.show("Failed to save About: " + err.statusText, "error");
          });
      };

      vm.startEdit = function () {
        vm.generalInfoEdit = _.cloneDeep(vm.generalInfo);
        vm.displayMode = 'edit';
      };

      vm.cancelEdit = function () {
        vm.displayMode = 'read';
      };

      vm.uploadFile = function (file) {
        if (!file) return;

        vm.isLoading = true;
        return UploadService.upload(file, 'generalInfoFile').then(function (response) {
          var resp = response.data;
          return $timeout(function () {
            vm.isLoading = false;

            vm.generalInfoEdit.files = vm.generalInfoEdit.files || [];
            vm.generalInfoEdit.files.push({
              name: file.name,
              format: resp.format,
              uri: resp.temporaryLocation.replace('/temp/', '/public/general-info-file/'),
            });

            FlashMessageService.show("upload successful", "success");
            return resp;
          });
        }, function (error) {
          vm.isLoading = false;
          return FlashMessageService.show("upload failed: " + error.statusText, "error");
        });
      };

      vm.removeFile = function (index) {
        vm.generalInfoEdit.files.splice(index, 1);
      };

      vm.copyFileImgTagToClipboard = function (file) {
        var compiled = _.template('<img src="${link}" alt="${name}" />');
        var fileLink = API_BASE_URL + 'download/?uri=' + file.uri + '&name=' + file.name;
        var imgTag = compiled({ link: fileLink, name: file.name });
        CopyToClipboardService(imgTag).then(function (success) {
          FlashMessageService.show("File <img> tag copied to clipboard", "success");
        }).catch(function (err) {
          FlashMessageService.show("Failed to copy file <img> tag to clipboard", "error");
        })
      };

      vm.copyFileLinkToClipboard = function (file) {
        var fileLink = API_BASE_URL + 'download/?uri=' + file.uri + '&name=' + file.name;
        CopyToClipboardService(fileLink).then(function (success) {
          FlashMessageService.show("File link copied to clipboard", "success");
        }).catch(function (err) {
          FlashMessageService.show("Failed to copy file link to clipboard", "error");
        })
      };

      function activate() {
        GeneralInfoService
          .getGeneralInfo()
          .then(function (response) {
            vm.generalInfo = response;
          })
          .catch(function (err) {
            FlashMessageService.show("Failed to load About: " + err.statusText, "error");
          });
      }
    },
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: 'templates/comp/general-info-editor.html'
  }
});

app.filter('joinarr', function () {
  return function join(array, prop, separator) {
    if (!_.isArray(array)) return array;
    if (!prop) return array.join(separator || '; ');

    var propArr = _.map(array, function (item) {
      return _.get(item, prop);
    });

    return propArr.join(separator || '; ');
  };
});
