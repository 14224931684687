app.service('PublishService', function (API_BASE_URL, $http, HelperService ) {

  var publishServiceBaseUrl = API_BASE_URL + 'publish-instruments';
  var unpublishServiceBaseUrl = API_BASE_URL + 'unpublish-instruments';
  var revertToPublishedBaseUrl = API_BASE_URL + 'instruments-revert-to-published';

  this.publish = function (ids) {
    return $http.get(publishServiceBaseUrl, {params: {ids: ids } } ).then(function (results) {
      return results.data;
    });
  };

  this.unpublish = function (ids) {
    return $http.get(unpublishServiceBaseUrl, {params: {ids: ids }}).then(function (results) {
      return results.data;
    });
  };

  this.revertToPublished = function (ids) {
    return $http.get(revertToPublishedBaseUrl, { params: { ids: ids } } ).then(function (results) {
      return results.data;
    });
  };

});


