app.service( 'SubscriptionService', function( API_BASE_URL, SSO_LOGIN_URL, $http, $rootScope ) {
    
    this.subscribe = function() {
        return $http.post( API_BASE_URL + 'subscribe' ).then( function( results ) {
            return results.data;
        } );
    };
    
    this.unsubscribe = function() {
        return $http.post( API_BASE_URL + 'unsubscribe', { params: {lang: $rootScope.lang } } ).then( function( results ) {
            return results.data;
        } );
    };
    
    this.isSubscribed = function() {
        return $http.get( API_BASE_URL + 'is-subscribed' ).then( function( results ) {
            return results.data;
        } );
    };
} );
