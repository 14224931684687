app.controller( "AdherenceListCtrl", function( $scope, $state, $stateParams, $window, AdherenceService, CommitteeService, ListService, HelperService ) {
    
    $scope.selectedAdherences = [];
    
    $scope.searchData = {
        types: [], committees: [], countries: [], themes: [], includeNotInForce: false
    };

    $scope.getEmailAdherences = function() {
        var instrumentsToPrint = [];
        if ($scope.selectedAdherences && $scope.selectedAdherences.length > 0) {
            instrumentsToPrint = _.map($scope.selectedAdherences, function (adh) {
                return adh.instrument;
            })
        } else if ($scope.countries && $scope.countries.length > 0) {
            instrumentsToPrint = _.reduce($scope.countries, function (acc, item) {
                item.adherences.forEach(function (adh) {
                    acc.push(adh.instrument)
                });
                return acc;
            }, []);
        }
        return _.uniqBy(instrumentsToPrint, 'id');
    };

    $scope.search = function( searchData ) {
        
        $scope.loading = true;
        
        var params = {
            typeIds: HelperService.extractIds( searchData.types ),
            committeeIds: HelperService.extractIds( searchData.committees ),
            themeIds: HelperService.extractIds( searchData.themes ),
            countryIds: HelperService.extractIds( searchData.countries ),
            includeNotInForce: searchData.includeNotInForce
        };

        if( !params.typeIds ) {
            delete  params.typeIds;
        }
        if( !params.committeeIds ) {
            delete  params.committeeIds;
        }
        if( !params.themeIds ) {
            delete  params.themeIds;
        }
        if( !params.countryIds ) {
            delete  params.countryIds;
        }

        if( !params.includeNotInForce ) {
            delete params.includeNotInForce;
        }

        AdherenceService.listGroupedByCountry( params ).then( function( countries ) {
            $scope.loading = false;
            $scope.countries = countries;
            $scope.loadedQuery = _.cloneDeep(searchData);

            angular.forEach( countries, function(  cou ) {
                angular.forEach( cou.adherences, function( adh ) {
                    adh.country = cou;
                    adh.uiId = _.uniqueId('adh_')
                })
            } );
        }, function( error ) {
            $scope.loading = false;
        } );
    };
    
    // select Adherence and Country for adding/removing classes for quick print
    $scope.toggleSelection = function( adherence, country ) {
        adherence.isSelected = !adherence.isSelected;
        
        var isCountrySelected = false;
        angular.forEach( country.adherences, function( adherence ) {
            if( adherence.isSelected ) {
                isCountrySelected = true;
            }
        } );
        country.isSelected = isCountrySelected;
    };
    
    ListService.list().then( function( lists ) {
        $scope.lists = lists;

        HelperService.removeDisabledItems( lists.countries );
        HelperService.removeDisabledItems( lists.instrumentTypes );
        HelperService.removeDisabledItems( lists.committees );
        HelperService.removeDisabledItems( lists.themes );

        $scope.searchData.countries =
            HelperService.selectAndAttachNamesWithIds( lists.countries, $stateParams.adherentIds );
        
        if( $scope.searchData.countries.length ) {
            $scope.search( $scope.searchData );
        }
    } );

    $scope.scrollTop = function() {
        $window.scroll({
                         top: 0,
                         left: 0,
                     });
    };

    $scope.shouldPrintCountry = function(country) {
        if(_.isEmpty($scope.selectedAdherences)) return true;

        var selectedUiIds = _.map($scope.selectedAdherences, 'uiId');
        var countryUiIds = _.map(country.adherences, 'uiId');

        return !_.isEmpty(_.intersection(selectedUiIds, countryUiIds));
    }
    
} );
