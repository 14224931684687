function listSort( a, b ) {
    if( a.status.id > b.status.id ) {
        return 1;
    }
    if( a.status.id < b.status.id ) {
        return -1;
    }
    
    if( a.adoptionDate < b.adoptionDate ) {
        return 1;
    }
    if( a.adoptionDate > b.adoptionDate ) {
        return -1;
    }
    return 0;
}

app.service( "InstrumentService",
             function( API_BASE_URL, MarkLogicApiAdapter, $http, $rootScope ) {
    
                 var serviceBaseUrl = API_BASE_URL + "instruments";
                 var draftInstrumentsBaseUrl = API_BASE_URL + "draft-instruments";
    
    
                 this.list = function( params ) {
                     params = typeof params !== "undefined" ? params : {};
                     params.lang = $rootScope.lang;
                     var sparams = MarkLogicApiAdapter.unformatInstrumentParams( params );
                     return $http.get( serviceBaseUrl, { params: sparams } ).then( function( results ) {
                         //do not sort if server response is based on relevancy
                         return ( params.term && params.searchScope === "fullText" ) ? results.data : results.data.sort( listSort );
                     } );
                 };
    
                 this.listDrafts = function() {
                     return $http.get( draftInstrumentsBaseUrl, { params: { lang: $rootScope.lang } } ).then( function( results ) {
                         return results.data;
                     } );
                 };
    
                 this.listPublished = function( params ) {
                     params = typeof params !== "undefined" ? params : {};
                     params.lang = $rootScope.lang;
                     return $http.get( serviceBaseUrl, { params: MarkLogicApiAdapter.unformatInstrumentParams( params ) } )
                                 .then( function( results ) {
                                     return results.data;
                                 } );
                 };
    
                 this.search = function( term ) {
                     return this.list( { term: term } );
                 };
    
                 this.fetch = function( id, preview ) {
                     return $http.get( serviceBaseUrl + "/" + id + (preview ? "/draft" : ""), { params: { lang: $rootScope.lang } } )
                                 .then( function( results ) {
                                     return MarkLogicApiAdapter.formatInstrument( results.data );
                                 } );
                 };
    
                 this.createNew = function() {
                     return $http.get( serviceBaseUrl + "/new" ).then( function( results ) {
                         return MarkLogicApiAdapter.formatInstrument( results.data );
                     } );
                 };
    
                 this.quickSearch = function( term ) {
                     return $http.get( serviceBaseUrl + "/keys", {
                         params: {
                             lang: $rootScope.lang,
                             term: term
                         }
                     } ).then( function( results ) {
                           results.data.hashset = {};
                           angular.forEach( results.data, function( item )  {
                                results.data.hashset[ "" + item.id ] = item;
                            } );

                         return results.data;
                     } );
                 };
    
                 this.store = function( instrument ) {
                     return $http.post( serviceBaseUrl, MarkLogicApiAdapter.unformatInstrument( instrument ) ).then( function( results ) {
                         return results.data;
                     } );
                 };
    
                 this.update = function( instrument ) {
                     return $http.put( serviceBaseUrl + "/" + instrument.id, MarkLogicApiAdapter.unformatInstrument( instrument ) )
                                 .then( function( results ) {
                                     return results.data;
                                 } );
                 };
    
                 this.delete = function( instrument ) {
                     return $http.delete( serviceBaseUrl + "/" + instrument.id ).then( function( results ) {
                         return results.data;
                     } );
                 };
             } );
