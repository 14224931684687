app.service('FlashMessageService', function ($rootScope, $timeout) {

  this.show = function (content, type) {
    $rootScope.flashMessage = {
      content: content,
      type: type
    };
    $timeout(this.clear, 4000);
  };

  this.clear = function () {
    $rootScope.flashMessage = false;
  };

});
