app.service( "ListService", function( API_BASE_URL, $http, $rootScope ) {
    
    var serviceBaseUrl = API_BASE_URL + "lists";

    this.list = function() {
        return $http.get( serviceBaseUrl, { params: { lang: $rootScope.lang } } )
                    .then( function( results ) {
                        results.data.year1 = [];
                        results.data.year2 = [];
                        results.data.year3 = [];

                        var cy = (new Date()).getFullYear();
                        for( ii = cy; ii >= 1952; ii--) {
                            results.data.year1.push( { id: ii, name: '' + ii } );
                            results.data.year2.push( { id: ii, name: '' + ii } );
                            results.data.year3.push( { id: ii, name: '' + ii } );
                        }

                        for( var key in results.data ) {
                            var list = results.data[ key ];
                            list.hashset = {};
                            for( ii = 0; ii < list.length; ii++) {
                                var it = list[ ii ];
                                it.active = (it.active == undefined) || it.active == "true";
                                list.hashset[ "" + it.id ] = it;
                            }
                        }

                        return results.data;
                    } );
    };
} );
