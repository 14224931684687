app.service('GeneralInfoService', function (API_BASE_URL, $http, $rootScope) {

  var serviceBaseUrl = API_BASE_URL + 'general-info';

  this.getGeneralInfo = function () {
    var config = { params: { lang: $rootScope.lang } };
    return $http.get(serviceBaseUrl, config).then(function (results) {
      results.data.text = fixApiLinks(results.data.text);
      return results.data;
    });
  };

  this.saveGeneralInfo = function (genInfo) {
    var putUrl = serviceBaseUrl + ('?lang=' + $rootScope.lang || 'en');
    return $http.put(putUrl, JSON.stringify(genInfo)).then(function (results) {
      return results.data;
    });
  }
});

function fixApiLinks(str) {
  if (!str) return str;
  try {
    var $str = angular.element("<div></div>").append(str);
    angular.forEach($str.find('a'), function (link) {
      if (link.getAttribute('href').indexOf('/api/download') > -1 && !link.getAttribute('target')) {
        link.setAttribute('target', '_blank');
      }
    });
    return $str.html();
  } catch (err) {
    return str;
  }
}
