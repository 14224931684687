app.controller( 'HomeCtrl',
    function( $scope,
              $rootScope,
              $state,
              $filter,
              $sce,
              RecentDevelopmentService,
              FlashMessageService,
              BasicStatService,
              ListService,
              HelperService,
              SubscriptionService) {
    
        $scope.searchData = {
            themes: [], types: [], committees: [], includeNotInForce: false, yearFrom: [], yearTo: []
        };
        $scope.loadingStats = true;
        $scope.loadingRecent = true;

        if( $rootScope.user.id !== '0' ) {
            SubscriptionService.isSubscribed().then( function( res ) {
                $rootScope.user.subscribed = res === 1;
                $scope.userSubscribed = $rootScope.user.subscribed;
            } );
        }

        RecentDevelopmentService.list().then( function( recentDevelopments ) {
            $scope.loadingRecent = false;
            $scope.recentDevelopments = recentDevelopments;
        } );

        BasicStatService.list().then( function( basicStats ) {
            $scope.loadingStats = false;
            $scope.basicStats = basicStats;
        } );

        ListService.list().then( function( lists ) {
            HelperService.removeDisabledItems( lists.instrumentTypes );
            HelperService.removeDisabledItems( lists.committees );
            HelperService.removeDisabledItems( lists.themes );

            $scope.lists = lists;

        } );
        

        $scope.search = function( searchData ) {
            var params = {
                mode: 'advanced',
                typeIds: HelperService.extractIds( searchData.types ),
                committeeIds: HelperService.extractIds( searchData.committees ),
                themeIds: HelperService.extractIds( searchData.themes ),
                yearFrom: searchData.yearFrom.length ?  searchData.yearFrom[ 0 ].name  : '',
                yearTo: searchData.yearTo.length ? searchData.yearTo[ 0 ].name : '',
                dateType: "adoption",
                searchScope: "titles"
            };
            params[ HelperService.inferSearchParamName( searchData.reference ) ] = searchData.reference;
            $state.go( 'app.instruments', params );
        };
        
        $scope.formatBasicStat = function( stat ) {
            var nt = "&nbsp;&nbsp;&nbsp;&nbsp;"
            var num = ""  + stat.count;
            return  $sce.trustAsHtml( "<span style='font-family:monospace;font-weight: bold;'>" + nt.slice( -6*( 5 - num.length ) ) + num  + "</span>&nbsp;" + stat.name );
        };
    

        $scope.listTypeInstruments = function( typeId ) {
            var params = {
                mode: 'advanced',
                typeIds: typeId,
                statusIds: 1
            };
            if( typeId == 0 ) {
                delete params.typeIds;
                
            }
            $state.go( 'app.instruments', params );
        };


        $scope.toggleSubscription = function() {

            if( $rootScope.user.id !== '0' ) {
                if( $scope.userSubscribed ) {
                    SubscriptionService.subscribe().then( function() {
                        $rootScope.user.subscribed = true;
                        FlashMessageService.show( $filter( "translate" )( "home.subsSuccessMessage" ), 'success' );
                    }, function( e ) {
                        FlashMessageService.show( $filter( "translate" )( "home.subsErrorMessage" ), 'error' );
                    } );
                } else {
                    SubscriptionService.unsubscribe().then( function() {
                        $rootScope.user.subscribed = false;
                        FlashMessageService.show( $filter( "translate" )( "home.unsubsSuccessMessage" ), 'success' );
                    }, function( e ) {
                        FlashMessageService.show( $filter( "translate" )( "home.subsErrorMessage" ), 'error' );
                    } );
                }
            } else {
                FlashMessageService.show( $filter( "translate" )( "home.loginRequired" ), 'error' );
            }
        };
    } );
