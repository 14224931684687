app.service('PrintoutService', function (API_BASE_URL, $http, $timeout, $rootScope, $window) {

  var serviceBaseUrl = API_BASE_URL + 'print?';

  this.create = function (instrumentIds, draft) {
    $window.open(serviceBaseUrl
      + "ids=" + instrumentIds.join()
      + (draft === undefined ? "" : "&draft=" + draft)
      + "&lang=" + $rootScope.lang, "_blank");
  };

  this.sendInstrumentsEmailViaBackend = function (instruments, recipients) {
    var postData = {
      ids: _.map(instruments, 'id').join('|'),
      to: _.isString(recipients) ? recipients : recipients.join(';'),
      lang: $rootScope.lang
    };

    return $http.post(
      API_BASE_URL + 'mail-list',
      toFormUrlEncoded(postData),
      {
        headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'}
      }
      ).then(function (results) {
      return results.data;
    });
  };

});

function toFormUrlEncoded(object) {
  var params = _.reduce(object, function(acc, val, key) {
    acc.push(encodeURIComponent(key) + '=' + encodeURIComponent(val));
    return acc;
  }, []);
  return params.join('&');
}
