app.config( [ "$translateProvider", function( $translateProvider ) {

    $translateProvider.useSanitizeValueStrategy( null );
    $translateProvider.preferredLanguage( "en" );

    $translateProvider.translations( "en", {
        header: {
            title: "OECD Legal Instruments",
            login: "Login",
            logout: "Logout",
            searchPlaceholder: "search...",
            menu: {
                home: "Home",
                generalInformation: "About",
                fullList: "Full list",
                advancedSearch: "Advanced search",
                notPublished: "Create/Publish",
                adherences: "Adherences",
                statistics: "Key figures"
            }
        },
        footer: {
            credits: "© OECD 2017 - OECD Legal Instruments"
        },
        home: {
            searchTitle: "Quick Search",
            searchHint: "Title or reference",
            recentDevelopmentsTitle: "Recent Developments",
            keyFiguresTitle: "Key Figures",
            searchButton: "Search",
            promptSubscription: "Inform me about recent developments",
            subsSuccessMessage: 'You have successfully subscribed to future update notification emails',// "L'abonnement aux futures notifications de mise à jour a été une réussite"
            unsubsSuccessMessage: "You have unsubscribed and will no longer receive update notification emails",
            subsErrorMessage: "An error occured, please try again", //"Une erreur est survenue, veuillez réessayer",
            loginRequired: 'Please login first to use this feature'
        },
        generalInformation: {
            title: "About",
            content: ""
        },

        stats: {
            mainTitle: "Statistics",
            "instruments-by-type": "Legal Instruments by Type",
            "in-force-by-year": "Legal Instruments in Force",
            "non-member-adherences-by-year": "Number of non-Member Adherences to OECD Legal Instruments",
            adherencesByYear: "Adherences by Year",
            "key-partner-adherences": "Adherences of Key Partners",
            "accession-countries-adherences": "Adherences of Accession Countries",
            "country-programmes-adherences": "Adherences of Country-Programme Countries",
            loading: "Loading the graph"
        },
        adherences: {
            title: "List of Adherences",
            loading: "Loading Adherences...",
            adherence: "Adherence",
            oecdMembers: "OECD Members",
            nonMembers: "Non-Members",
            others: 'Other',
            documents: "Documents",
            close: "Close",
            viewOther: "Other adherences",
            resultCount: "Number of adherences:",
        },
        misc: {
            currentLanguage: "English",
            start: "Start",
            end: "End",
            search: "Search",
            date: "Date",
            country: "Adherent",
            status: "Status",
            type: "Type",
            references: "References",
            filter: "Filter",
            clearFilter: "Clear",
            AdherentMandatory: "* At least one adherent must be selected before starting the search",
            loading: "Loading...",
            none: 'None',
            downloadInstrumentButton: "Download/Print Booklet",
            downloadInstrumentTooltip: "Download or print the booklet of this legal instrument (PDF file)",
            noResults: "No results...",
            shareLinkButton: "Share Link",
            shareLinkTooltip: "Send a link to this page by email",
            shareSelectionLink: "Share selection link",
            edit: "Edit"
        },
        dates: {
            documentReferences: "Document's references",
            minutesDocReferences: "Summary record references",
            noResults: "No dates for this instrument."
        },
        instruments: {
            normalTitle: " Full list of legal instruments",
            createNewInstrument: "Create new instrument",
            id: "ID",
            instrumentId: "Instrument ID",
            name: "Title",
            status: "Status",
            dateOfPublish: "Date of publish",
            dateOfAdoption: "Date of adoption",
            dateOfInForce: "Date of entry into force",
            dateOfAbrogation: "Date of abrogation",
            dateOfLastAmendment: "Date of last amendment",
            instrument: "Instrument",
            loading: "Loading instruments...",
            resultCount: "Number of legal instruments:",
            reference: "Reference",
            buttons: {
                preview: "Preview",
                open: "Open",
                edit: "Edit"
            }

        },
        instrument: {
            titles: {
                mainText: "Text",
                committees: "Committee(s)",
                dates: "Date(s)/Reference(s)",
                backgroundInformation: "Background information",
                relatedInstruments: "Related instrument(s)",
                supportDocuments: "Related document(s)",
                adherents: "Adherents",
                adoptedOn: "Adopted on",
                lastAmendmentOn: "Last amended on",
                dateOfAbrogation: "Date of abrogation",
                dateOfInForce: "Date of entry into force",
                monitoring: "Implementation",
                legInternalNotes: "LEG internal notes",
                involvedCommittees: "Other Involved Committee(s)",
                parentCommittees: "Parent Committee(s)",
                thereIsErrors: "The instrument could not be saved for those reasons:",
                draftText: "DRAFT",
                publishedReport: 'Published version',
                translations: 'Translations',
                unofficialTranslations: 'Unofficial translations',
                hostedDocuments: 'Hosted documents',
            }
        },
        filters: {
            types: "Type(s)",
            committees: "Committee(s)",
            themes: "Theme(s)",
            status: "Status",
            yearOfAdoption: "Year of adoption",
            yearOfInForce: "Year of entry into force",
            yearOfAbrogation: "Year of abrogation",
            yearOfLastAmendment: "Year of last amendment",
            adherents: "Adherent(s)",
            referenceId: "Reference",
            monitoring: "Monitoring clause",
            monitoringYear: "Year of monitoring",
            asNeeded: "As needed",
            everyXYears: "Every X years",
            withinXYearsThenAsNeeded: "Within X years then as needed",
            freeTextSearch: "Full-text search",
            freeTextSearchPlaceholder: "Search...",
            searchScope: "Search scope",
            inAllText: "In all text",
            inTitles: "In titles",
            searchType: "Search type",
            exactPhrase: "Exact phrase",
            withAllWords: "With all words",
            publishStatus: "Publish status",
            notPublished: "Not published",
            published: "Published",
            publishDate: "Publish date",
            startDate: "From",
            endDate: "To",
            filter: "Filter",
            dateFilters: "Date",
            searchPlaceholder: "Instrument ID or ONE reference",
            includeNotInForce: "Include legal instruments not in force",
            yearInvalid: "The year must be between 1952 and {max}",
            searchError: 'The search could not be executed',
            activatedTitle: 'Activated filters',
            keywords: 'Keywords'
        },
        printout: {
            title: 'OECD Legal Instruments',
            disclaimer: '<p>This list has been created from the Compendium of OECD Legal Instruments and includes a selection of legal instruments.</p> <p>For access to the official and up-to-date information, including the full text of the selected legal instruments, please consult the Compendium at <a href="https://legalinstruments.oecd.org">https://legalinstruments.oecd.org</a></p>',
            downloadPrintListButton: 'Download/Print List',
            shareListButton: 'Share List',
            printSelection: "Print selection",
            printSelectionWithMainText: "Download selection as a single PDF document",
            printSelectionWithMainTextAndMetaData: "Print selection with main text and meta data",
            printSingleWithMainText: "Print instrument with main text",
            printSingleWithMainTextAndMetaData: "Print instrument with main text and meta data",
            shareListByMailRecipients: 'Email recipients:',
            shareListByMailViaBackendSuccess: 'E-mail message has been sent successfully',
            shareListByMailViaBackendError: 'Oops, something went wrong: {{errorDescription}}',
            validation: {
                noRecipients: 'Missing or invalid recipients',
                emailValidationFailed: 'Failed to validate some of recipient emails.',
                emailsExcluded: 'Emails excluded: {{mails}}',
                emailsIncluded: 'Mail will be sent to: {{mails}}'
            }
        },
        monitoring: {
            clause: "Clause",
            year: "Year",
            documentReferences: "Document reference(s)"
        },
        mainText: {
            loading: "Loading..."
        },
        pdf: {
            page: "Page: ",
            download: "Download"
        },
        relatedInstruments: {
            replacedBy: "Replaced by",
            replaces: "Replaces",
            relatedTo: "Related to"
        },
        error404: {
            title: "404 The page you are looking is not found"
        },

        error403: {
            title: "403 You are not authorized"
        }
    } );

    $translateProvider.translations( "fr", {
        header: {
            title: "Instruments juridiques de l’OCDE",
            login: "Connexion",
            logout: "Déconnexion",
            searchPlaceholder: "rechercher...",
            menu: {
                home: "Accueil",
                generalInformation: "À propos",
                fullList: "Liste complète",
                advancedSearch: "Recherche avancée",
                notPublished: "Créer/Publier",
                adherences: "Adhésions",
                statistics: "Chiffres clés"
            }
        },
        footer: {
            credits: "© OCDE 2017 - Instruments juridiques de l’OCDE"
        },
        home: {
            searchTitle: "Recherche rapide",
            searchHint: "Titre ou référence",
            recentDevelopmentsTitle: "Derniers développements",
            keyFiguresTitle: "Chiffres clés",
            searchButton: "Rechercher",
            promptSubscription: "Être informé(e) des derniers développements",
            subsSuccessMessage: "Vous êtes désormais inscrit aux notifications par email pour les futures mises à jour",
            unsubsSuccessMessage: "Vous êtes désinscrit et ne recevrez plus de notifications par email pour les futures mises à jour",
            subsErrorMessage: "Une erreur s’est produite, merci de bien vouloir réessayer",
            loginRequired: 'Merci de vous connecter pour l\'utilisation de cette fonction'
        },
        generalInformation: {
            title: "À propos",
            content: "Mercedem aut nummos unde unde extricat, amaras. Qui ipsorum lingua Celtae, nostra Galli appellantur. Petierunt uti sibi concilium totius Galliae in diem certam indicere. Quisque placerat facilisis egestas cillum dolore."
        },
        stats: {
            mainTitle: "Statistiques",
            "instruments-by-type": "Instruments Juridiques par Type",
            "in-force-by-year": "Instruments Juridiques en Vigueur",
            "non-member-adherences-by-year": "Nombre d'Adhésions de non-Membres aux Instruments Juridiques de l'OCDE",
            adherencesByYear: "Adhésions par an",
            "key-partner-adherences": "Adhésions des Partenaires Clés",
            "accession-countries-adherences": "Adhésions des Pays Candidats à l'Adhésion",
            "country-programmes-adherences": "Adhésions des Programmes par Pays",
            loading: "Chargement de graphique"
        },
        adherences: {
            title: "Liste des adhérences",
            loading: "Chargement des adhérences...",
            adherence: "Adhésion",
            oecdMembers: "Membres de l'OCDE",
            nonMembers: "Non-Membres",
            documents: "Documents",
            others: 'Autre',
            close: "Fermer",
            viewOther: "Autres adhérences",
            resultCount: "Nombre d'adhérences :",
        },
        misc: {
            currentLanguage: "Français",
            start: "Début",
            end: "Fin",
            search: "Rechercher",
            date: "Date",
            country: "Adhérent",
            status: "Statut",
            type: "Type",
            references: "References",
            filter: "Filtrer",
            clearFilter: "Réinitialiser",
            AdherentMandatory: "* Un adhérent au moins doit être sélectionné pour lancer la recherche",
            loading: "Chargement...",
            none: 'Aucun',
            downloadInstrumentButton: "Télécharger/imprimer la brochure",
            downloadInstrumentTooltip: "Download or print the booklet of this legal instrument (PDF file)",
            noResults: "Aucun résultat...",
            shareLinkButton: "Partager le lien",
            shareLinkTooltip: "Partager lien",
            shareSelectionLink: "Partager un lien vers cette sélection",
            edit: "Éditer"
        },
        dates: {
            documentReferences: "Cote",
            minutesDocReferences: "Minutes cote",
        },
        instruments: {
            normalTitle: "Liste complète des instruments juridiques",
            createNewInstrument: "Créer un nouvel instrument",
            id: "Identifiant",
            instrumentId: "Identifiant de l'instrument",
            name: "Titre",
            status: "Statut",
            dateOfPublish: "Date de publication",
            dateOfAdoption: "Date d'adoption",
            dateOfInForce: "Date d'entrée en force",
            dateOfAbrogation: "Date d'abrogation",
            dateOfLastAmendment: "Date de la dernière modification",
            instrument: "Instrument",
            loading: "Chargement des instruments...",
            resultCount: "Nombre d'instruments juridiques :",
            reference: "Référence",
            buttons: {
                preview: "Aperçu",
                open: "Ouvrir",
                edit: "Éditer"
            }
        },

        instrument: {
            titles: {
                mainText: "Texte",
                committees: "Comité(s)",
                dates: "Date(s)/Référence(s)",
                backgroundInformation: "Informations générales",
                relatedInstruments: "Instrument(s) associé(s)",
                supportDocuments: "Document(s) associé(s)",
                adherents: "Adhérents",
                adoptedOn: "Adopté le",
                dateOfInForce: "Date d'entrée en force",
                dateOfAbrogation: "Date d'abrogation",
                lastAmendmentOn: "Modifié le",
                monitoring: "Mise en œuvre",
                legInternalNotes: "Notes internes LEG",
                involvedCommittees: "Comités impliqués",
                parentCommittees: "Comités parents",
                thereIsErrors: "L'instrument n'a pas pu être sauvegardé pour les raisons suivantes :",
                draftText: "ÉBAUCHE",
                publishedReport: 'Version publiée',
                translations: 'Translations',
                unofficialTranslations: 'Traductions non officielles',
                hostedDocuments: 'Hosted documents',
            }
        },
        filters: {
            types: "Type(s)",
            committees: "Comité(s)",
            adherents: "Adhérents",
            themes: "Thème(s)",
            status: "Statut",
            yearOfAdoption: "Année d'adoption",
            yearOfInForce: "Année d'entrée en vigueur",
            yearOfAbrogation: "Année d'abrogation",
            yearOfLastAmendment: "Année du dernier amendement",
            adherents: "Adhérents",
            referenceId: "Référence",
            monitoring: "Clause de suivi",
            monitoringYear: "Année du suivi",
            asNeeded: "Comme requis",
            everyXYears: "Tous les X ans",
            withinXYearsThenAsNeeded: "Dans les X ans puis comme requis",
            freeTextSearch: "Recherche plein texte",
            freeTextSearchPlaceholder: "Rechercher...",
            searchScope: "Périmètre de la recherche",
            inAllText: "Dans tout le texte",
            inTitles: "Dans les titres",
            searchType: "Type de recherche",
            exactPhrase: "Phrase exacte",
            withAllWords: "Avec tous les mots",
            publishStatus: "Statut de publication",
            notPublished: "Non publié",
            published: "Publié",
            publishDate: "Date de publication",
            startDate: "De",
            endDate: "À",
            filter: "Filtre",
            dateFilters: "Date",
            searchPlaceholder: "Identifiant de l'instrument ou référence ONE",
            includeNotInForce: "Inclure les instruments juridiques non entrés en vigueur",
            yearInvalid: "L'année doit être entre 1952 et {max}",
            searchError: 'Impossible de lancer la recherche',
            activatedTitle: 'Filtres activés',
            keywords: 'Mots-clés'
        },
        printout: {
            title: 'Liste d’instruments juridiques de l’OCDE',
            disclaimer: '<p>Cette liste a été créée à partir du Recueil des instruments juridiques de l’OCDE et comprend une sélection d’instruments juridiques.</p> <p>Pour accéder aux informations officielles et à jour, notamment le texte intégral des instruments juridiques sélectionnés, veuillez consulter le Recueil des instruments juridiques de l’OCDE : <a href="http://legalinstruments.oecd.org">http://legalinstruments.oecd.org</a></p>',
            downloadPrintListButton: 'Télécharger/Imprimer la liste',
            shareListButton: 'Partager la liste',
            printSelection: "Imprimer la sélection",
            printSelectionWithMainText: "Télécharger la sélection en un seul document PDF",
            printSelectionWithMainTextAndMetaData: "Imprimer la sélection avec le texte principal et les meta-données",
            printSingleWithMainText: "Imprimer l'instrument avec le texte principal",
            printSingleWithMainTextAndMetaData: "Imprimer l'instrument avec le texte principal et les meta-données",
            shareListByMailRecipients: 'Email recipients:',
            shareListByMailViaBackendSuccess: 'E-mail message has been sent successfully',
            shareListByMailViaBackendError: 'Oops, something went wrong: {{errorDescription}}',
            validation: {
                noRecipients: 'Missing or invalid recipients',
                emailValidationFailed: 'Failed to validate some of recipient emails.',
                emailsExcluded: 'Emails excluded: {{mails}}',
                emailsIncluded: 'Mail will be sent to: {{mails}}'
            }
        },
        monitoring: {
            clause: "Clause",
            year: "Année",
            documentReferences: "Référence(s) de document(s)"
        },
        mainText: {
            loading: "Chargement..."
        },
        pdf: {
            page: "Page: ",
            download: "Télécharger"
        },
        relatedInstruments: {
            replacedBy: "Remplacé par",
            replaces: "Remplace",
            relatedTo: "Relié à"
        },
        error404: {
            title: "404 Page non trouvée"
        },

        error403: {
            title: "403 Vous n'êtes pas autorisé"
        }

    } );


} ] );
