app.controller( "StatsCtrl", function( $scope, $state, StatService, $filter, $window ) {
    
    var statList = [ "instruments-by-type",
        "in-force-by-year",
        "non-member-adherences-by-year",
        "key-partner-adherences",
        "accession-countries-adherences",
        "country-programmes-adherences" ];

    $scope.selectedStackedBarChart = statList[ 4 ];
    $scope.flights = {};
    $scope.flights.flightsProcessed = false;
    
    var stats = {};
    //todo: if the number of stats increase, increase below number 8
    var colors = palette( "tol-rainbow", 8 ).map( function( hex ) {
        return '#' + hex;
    } );
    
    angular.forEach( statList, function( statId, idx ) {
        $scope[ 'loading-'+ statId ] = true;
    } );
    
    angular.forEach( statList, function( statId, idx ) {
        $scope[ "loading-" + statId ] = true;
        StatService.show( statId ).then( function( data ) {
            var stat = {
                data: data,
                options: {
                    legend: {
                        display: false,
                        position: "right"
                    },
                    title: {
                        display: true,
                        text: $filter( "translate" )( "stats." + statId ),
                        fontFamily: 'Open Sans',
                        fontSize: 14
                    },
                    animation: {
                        duration: 2000,
                        onComplete: function(animation) {
                            $scope[ 'loading-'+ statId ] = false;
                        }
                    },
                    tooltips: {
                        //enabled: true,
                        mode: "index",
                        intersect: false,
                        displayColors: data.datasets.length > 1 || idx === 0,
                        callbacks: {
                            label: function( tooltipItem, data) {
                                if( idx === 0 ) {
                                    return data.labels[ tooltipItem.index ] + ': ' + data.datasets[ 0 ].data[ tooltipItem.index ];
                                }
                                if( data.datasets && data.datasets.length > 1 ) {
                                    return data.datasets[ tooltipItem.datasetIndex ].label + ': ' + tooltipItem.yLabel;
                                }
                                return tooltipItem.yLabel;
                                
                            },
                            title: function( tooltipItem, data) {
                                return tooltipItem[ 0 ].xLabel;
                            }
                        }
                    },
                    responsive: true,
                    maintainAspectRatio:  false,
                    // String - Template string for single tooltips
                    tooltipTemplate: "<%if (label){%><%=label %>: <%}%><%= value %>",
                    // String - Template string for multiple tooltips
                    multiTooltipTemplate: "<%= value %>",
                }
            };
    
            if( idx === 0 ) {
                stat.options.legend.display = true;
                stat.options.tooltips.enabled = false;
                stat.options.legend.position = "bottom";

                stat.options.pieceLabel = {
                    // render 'label', 'value', 'percentage', 'image' or custom function, default is 'percentage'

                    render: 'value',
                    /*
                        function (args) {
                        // args will be something like:
                        // { label: 'Label', value: 123, percentage: 50, index: 0, dataset: {...} }
                        if( args.percentage >= 3 ) {
                            return args.value.toString();
                        }
                        return '';

                        // return object if it is image
                        // return { src: 'image.png', width: 16, height: 16 };
                    }, */

                    // position to draw label, available value is 'default', 'border' and 'outside'
                    // default is 'default'
                    position: 'border',
                    fontSize: 12,
                    fontColor: 'white',
                    // draw label even it's overlap, default is false
                    overlap: true,
                };

                //stat.options.tooltips.enabled = true;
                data.datasets[ 0 ].backgroundColor = colors;
                data.datasets[ 0 ].borderColor = "silver";
        
            } else if( idx <= 2 ) {
                angular.forEach( data.datasets, function( ds ) {
                    ds.backgroundColor = colors[ idx ];
                    ds.borderColor = "silver";
                } );
    
            } else {
                colors = colors;
                
                angular.forEach( data.datasets, function( ds, dsidx ) {
                    ds.backgroundColor = colors[ dsidx ];
                    ds.borderColor = "silver";
                    ds.xAxisID = 'x-axis';
                    ds.yAxisID = 'y-axis';
                } );

                stat.options.legend.display = true;
                stat.options.legend.position = "bottom";
                stat.options.scales = {
                    xAxes: [ {
                        id: 'x-axis',
                        stacked: true,
                        ticks: {
                            stepSize: 1,
                            min: 0,
                            autoSkip: false
                        }
                    } ],
                    yAxes: [ {
                        id: 'y-axis',
                        stacked: true
                    } ]
                };
            }
            stats[ statId ] = stat;

            var chartWithDropdown = [statList[3],statList[4],statList[5]];
            if(chartWithDropdown.indexOf(statId) > -1) {
                var noChartData = _.isEmpty(_.get(stat, 'data.labels'));
                if(statId === statList[4] && noChartData) {
                    $scope.selectedStackedBarChart = statList[ 3 ]
                }
                if(noChartData) $scope['nodata-'+statId] = noChartData;
            }

        } );
    } );

    $scope.stats = stats;


    $window.addEventListener('resize', function(){
        $scope.adjustStackGraphs();
    }, true);

    angular.element(document).ready( function(){
        $scope.adjustStackGraphs();
     } );

    $scope.adjustStackGraphs = function() {
        var div = document.getElementById( 'selectedStackedBarChartHolder' )
        if( div ) {
            div.style.height = (parseFloat( window.getComputedStyle( document.getElementById( "adherenceByYearChartHolder" ) ).height ) - 60) + "px";
        }
    }
} );
