app.controller( "InstrumentEditCtrl",
    function( $rootScope,
              $scope,
              $state,
              $stateParams,
              $window,
              $timeout,
              $sce,
              $http,
              $sanitize,
              $filter,
              InstrumentService,
              UploadService,
              FlashMessageService,
              PublishService,
              CopyToClipboardService,
              HelperService ) {


        // Allowed users only
        if( !$rootScope.user.canDo( "editInstruments" ) ) {
            return $state.go( "app.403" );
        }

        function getAncestor( el, className ) {
            while( !el.classList.contains( className ) ) {
                el = el.parentElement;

            }
            return el;
        }

        function findWithAttr( array, attr, value ) {
            for( i = 0; i < array.length; i += 1 ) {
                if( array[ i ][ attr ] === value ) {
                    return i;
                }
            }
            return -1;
        }

        $scope.updateMainTextViewer = function( lang, format, url ) {
            if( format === "pdf" ) {
                var uri = url + "#view=fitH,100&scrollbar=1&toolbar=0&navpanes=1";
                $scope[ "mainTextUrl_pdf_" + lang ] = $sce.trustAsUrl( url + "#view=fitH&scrollbar=1&toolbar=0&navpanes=1" );
                $scope[ "mainTextHtml_" + lang ] = null;
                $scope[ "mainText_pdf_obj_" + lang ] =  $sce.trustAsHtml( '<object data="' + uri + '" type="application/pdf" height="900px" width="100%">\n'
                                                            + '<p>Your browser does not suppport viewing PDF files, '
                                                            + 'or not configured properly to display embedded pdf files.</p>'
                                                      + '</object>' );
            } else {
                $scope[ "mainText_pdf_obj_" + lang ] = "";
                $scope[ "mainTextUrl_pdf_" + lang ] = "";
                $scope[ "mainTextHtml_" + lang ] = "";
                if( url ) {
                    $http.get( url ).then( function( response ) {
                        $scope[ "mainTextHtml_" + lang ] = response.data;
                    } );
                }
            }
        };

        $scope.removeBodyText = function( idx ) {
            if( $scope.instrument.bodyText.ref.length > idx ) {
                var ref = $scope.instrument.bodyText.ref[ idx ];
                ref.uri = "";
                $scope.updateMainTextViewer( ref.lang, ref.format, ref.uri );
            }
        };

        $scope.removeOrigDoc = function( idx ) {
            if( $scope.instrument.bodyText.orig.length > idx ) {
                var ref = $scope.instrument.bodyText.orig[ idx ];
                ref.uri = "";
                var lang = ( ["en", "fr"] )[ idx ];
                $scope[ "originalFileName_" + lang ] = "";
                $scope[ "originalFileUrl_" + lang ] = "";

            }
        };

        $scope.removeCoverImage = function( value ) {
            $scope.instrument.coverImage.uri = "";
        };

        $scope.saveCoverImage = function( value ) {
            $scope.instrument.coverImage = value;
        };
        $scope.addCoverImage = function() {
            $scope.instrument.coverImage = { isNew: true };
        }

        $scope.goToField = function( name ) {

            // Select the right tab
            var el = document.getElementsByName( name )[ 0 ];
            var tab = getAncestor( el, "tab" );
            var tabName = angular.element( tab ).attr( "data-name" );
            var index = findWithAttr( $scope.pivots, "id", tabName );
            $scope.selectedPivot = $scope.pivots[ index ];

            // Select the right "lang tab" (main text only)
            if( tabName === "mainText" ) {
                var langTab = getAncestor( el, "lang-tab" );
                var langTabName = angular.element( langTab ).attr( "data-name" );
                var langIndex = findWithAttr( $scope.langPivots, "id", langTabName );
                $scope.selectedMainTextPivot = $scope.langPivots[ langIndex ];
            } else if( tabName === "supportDocuments") {
                var langTab = getAncestor( el, "lang-tab" );
                var langTabName = angular.element( langTab ).attr( "data-name" );
                var langIndex = findWithAttr( $scope.langPivots, "id", langTabName );
                $scope.selectedSupportDocPivot = $scope.langPivots[ langIndex ];
            }

            // Once tab is rendered we scroll to invalid element
            $timeout( function() {
                el.scrollIntoView();
                // Scroll offset
                window.scrollBy( 0, -40 );
            } );
        };

        $scope.addNewDate = function() {
            $scope.instrument.changeHistory.update.push( {
                                                             type: "",
                                                             date: "",
                                                             OLISRef: [],
                                                             minutesOLISRef: [],
                                                             OLISRefEditor: "",
                                                             minutesOLISRefEditor: ""
                                                         } );
        };


        $scope.addNewTranslation = function() {
          $scope.instrument.translations = $scope.instrument.translations || { translation: [] };
          $scope.instrument.translations.translation.push({});
        };

        $scope.removeTranslation = function(index) {
          $scope.instrument.translations.translation.splice(index, 1);
        };

        $scope.uploadTranslationFile = function(itemToUpdate, file) {
          if(!file) return;

          $scope.loading = true;
          return UploadService.upload( file, 'file' ).then( function( response ) {
            var resp = response.data;
            return $timeout( function() {
              $scope.loading = false;

              itemToUpdate.file = {
                name: file.name,
                format: resp.format,
                uri: '/private' + resp.temporaryLocation,
              };

              FlashMessageService.show( "upload successful", "success" );
              return resp;
            } );
          }, function( error ) {
            $scope.loading = false;
            return FlashMessageService.show( "upload failed: " + error.statusText, "error" );
          });
        };

        $scope.removeTranslationFile = function(translation) {
          delete translation.file;
        };

        $scope.addNewHostedDoc = function() {
          $scope.instrument.hostedDocuments = $scope.instrument.hostedDocuments || [];
          $scope.instrument.hostedDocuments.push({});
        };

        $scope.removeHostedDoc = function(index) {
          $scope.instrument.hostedDocuments.splice(index, 1);
        };

        $scope.copyToClipboard = function(text) {
          CopyToClipboardService(text).then(function(success) {
            FlashMessageService.show("File link copied to clipboard", "success");
          }).catch(function(err) {
            FlashMessageService.show("Failed to copy file link to clipboard", "error" );
          })
        };

        $scope.deleteUpdateItem = function( index ) {
            $scope.instrument.changeHistory.update.splice( index, 1 );
        };

        $scope.upload = function( file, type, lang ) {

            if( !file ) {
                return;
            }

            $scope.loading = true;
            return UploadService.upload( file, type ).then( function( response ) {
                var resp = response.data;
                return $timeout( function() {
                    $scope.loading = false;
                    var langIdx = lang === "en" ? 0 : 1

                    var ref = {
                        format: resp.format,
                        lang: lang,
                        uri: resp.temporaryLocation
                    };
                    // If we just uploaded a mainText, we update it in the instrument Object
                    if( type === "mainText" ) {
                        if( langIdx >= $scope.instrument.bodyText.ref.length  ) {
                            $scope.instrument.bodyText.ref.push( {} );
                        }
                        $scope.instrument.bodyText.ref[ langIdx ] = ref;
                        $scope.updateMainTextViewer( lang, resp.format, resp.temporaryLocation );
                    } else if( type === "orig" ) {
                        if( langIdx >= $scope.instrument.bodyText.orig.length  ) {
                            $scope.instrument.bodyText.orig.push( {} );
                        }
                        $scope.instrument.bodyText.orig[ langIdx ] = ref;
                        $scope[ "originalFileName_" + lang ] = $scope.getOriginalFileName( lang );
                        $scope[ "originalFileUrl_" + lang ] = $sce.trustAsUrl( resp.temporaryLocation );

                    } else if( type === 'manuallyUploadedBooklet') {
                      $scope.instrument.manuallyUploadedBooklet[lang] = {
                        lang: lang,
                        format: resp.format,
                        uri: '/private' + resp.temporaryLocation,
                      };
                    }
                    FlashMessageService.show( "upload successful", "success" );
                    return resp;
                } );
            }, function( error ) {
                $scope.loading = false;
                return FlashMessageService.show( "upload failed: " + error.statusText, "error" );
            } );
        };

        $scope.preview = function( lang ) {
            $window.open( $state.href( "app.instrumentShow", {
                id: $scope.instrument.id,
                lang: lang,
                preview: "true"
            } ) );
        };

        $scope.save = function( instrument ) {

            //update replaced by dates
            angular.forEach( $scope.instrument.relations.replacedBy, function( replacedBy ) {
                replacedBy.date = $scope.instrument.relations.replacedByDate;
            } );

            // we update current instrument if already created
            InstrumentService.update( instrument ).then( function( results ) {

                FlashMessageService.show( "Instrument updated", "success" );
                $scope.editInstrumentForm.$setPristine();
                $scope.errors = [];
                $scope.invalidValues = [];
                $state.go( "app.instrumentEdit", {
                    id: instrument.id,
                    lang: $rootScope.lang
                } );
            }, function( error ) {
                $scope.editInstrumentForm.$setPristine();
                FlashMessageService.show( "Could not save this instrument: " + error.statusText, "error" );

                $scope.errors = error.data.errors;
                $scope.invalidValues = [];
                angular.forEach( $scope.errors, function( error ) {
                    if( error.field.indexOf( "OLISRef" ) > -1 ) {
                        error.field = error.field.substring( 0, error.field.indexOf( "OLISRef" ) + 7 );
                    }
                    $scope.invalidValues.push( error.field );
                } );
            } );
        };


        $scope.publish = function( id ) {
            PublishService.publish( id ).then( function() {
                $state.go( "app.instrumentShow", {
                    id: $scope.instrument.id,
                    lang: $rootScope.lang,
                    preview: "false"
                });
                FlashMessageService.show( "Instrument published", "success" );

            }, function( error ) {
                FlashMessageService.show( "Could not publish instrument:" + error.statusText, "error" );
            } );
        };

        $scope.unpublish = function( id ) {
            PublishService.unpublish( id ).then( function() {
                $state.reload();
                FlashMessageService.show( "Instrument un-published", "success" );
            }, function( error ) {
                FlashMessageService.show( "Could not un-publish instrument: " + error.statusText, "error" );
            } );
        };

        $scope.revertToPublished = function( id ) {
            PublishService.revertToPublished( id.toString() ).then( function() {
                $state.reload();
                FlashMessageService.show( "Instrument reverted to published version", "success" );
            }, function( error ) {
                FlashMessageService.show( "Could not revert to published version: " + error.statusText, "error" );
            } );
        };

        $scope.delete = function( instrument ) {
            InstrumentService.delete( instrument ).then( function() {
                $state.go( "app.instruments", {
                    lang: $rootScope.lang,
                    mode: "normal"
                } );
                FlashMessageService.show( "The instrument DRAFT has been deleted", "success" );
            }, function( error ) {
                FlashMessageService.show( "Could not delete instrument DRAFT: " + error.statusText, "error" );
            } );
        };

        $scope.cancel = function() {
            $state.go( "app.instruments", {
                lang: $rootScope.lang,
                mode: "normal"
            } );
            FlashMessageService.show( "Modifications discarded!", "success" );
        };


        $scope.deleteAdherenceRef = function( adherence, documentIndex, ref ) {
            if( documentIndex > -1 ) {
                adherence.documentRef.splice( documentIndex, 1 );
            }
        };

        $scope.saveAdherenceRef = function( adherence, index, value ) {
            if( index >= 0 ) {
                adherence.documentRef[ index ] = value;
            } else {
                adherence.documentRef.push( ref );
            }
        }

        $scope.addAdherenceRef = function( adherence ) {
            adherence.documentRef.push( { isNew: true });
        };

        $scope.editSupportRef = function( index, ref ) {
            if( index < 0 ) {
                $scope.instrument.bodyText.support.push( { isNew: true } );
                return;
            }
            $scope.instrument.bodyText.support[ index ] = ref;
        };

        $scope.deleteSupportRef = function( documentIndex, ref ) {
            if( documentIndex > -1 ) {
                $scope.instrument.bodyText.support.splice( documentIndex, 1 );
            }
        };

        $scope.removeManualBooklet = function(lang) {
          if(!$scope.instrument.manuallyUploadedBooklet) return;
          delete $scope.instrument.manuallyUploadedBooklet[lang];
        };

        $scope.prepareForEdit = function( formatted ) {
            var lists = formatted[ 0 ];
            var instrument = formatted[ 1 ];

            instrument.selectedStatus = '' + instrument.status.id;
            instrument.selectedType = '' + instrument.type.id;

            //multi-select requires its own copy of list to control selection list
            lists.otherCommittees = angular.copy( lists.committees );

            HelperService.selectAndAttachNames( lists.themes, instrument.themes.theme );
            HelperService.selectAndAttachNames( lists.committees, instrument.committees.parentCommittee );
            HelperService.selectAndAttachNames( lists.otherCommittees, instrument.committees.involvedCommittee );

            angular.forEach( instrument.changeHistory.update, function( update ) {
                HelperService.selectAndAttachNames( lists.themes, instrument.themes.theme );
            } );

            $scope.selectedMainTextPivot = $scope.langPivots[ 0 ];

            InstrumentService.quickSearch( "" ).then( function( data ) {
                $scope.replacingInstrumentsList = data;
                $scope.relatedInstrumentsList = angular.copy( data );

                HelperService.selectAndAttachNames( $scope.replacingInstrumentsList, instrument.relations.replacedBy );
                HelperService.selectAndAttachNames( $scope.relatedInstrumentsList, instrument.relations.relatedTo );

                $scope.lists = lists;
                $scope.instrument = instrument;

                $scope.instrument.relations.replacedByDate = instrument.relations.replacedBy.length
                                                                         && instrument.relations.replacedBy[ 0 ].date
                                                             ? instrument.relations.replacedBy[ 0 ].date
                                                             : null;

                $scope.mainText_html_en = "";
                $scope.mainText_html_fr = "";
                $scope.mainTextUrl_pdf_en = "";
                $scope.mainTextUrl_pdf_fr = "";
                angular.forEach( instrument.bodyText.ref, function( ref ) {
                    $scope.updateMainTextViewer( ref.lang, ref.format, ref.uri );
                } );

                angular.forEach( instrument.bodyText.orig, function( ref ) {
                    if( ref.uri ) {
                        $scope[ "originalFileUrl_" + ref.lang ] = $sce.trustAsUrl( ref.uri );
                        $scope[ "originalFileName_" + ref.lang ] = $scope.getOriginalFileName( ref.lang );
                    } else {
                        $scope[ "originalFileUrl_" + ref.lang ] = "";
                        $scope[ "originalFileName_" + ref.lang ] = "";
                   }
                } );

                if( instrument.coverImage.uri ) {
                    $scope.coverImageUrl = $sce.trustAsUrl( instrument.coverImage.uri );
                }
            } );
        };

        $scope.getOriginalFileName = function( lang ) {
            if( !$scope.instrument.key ) {
                return "original_" + lang + ".docx";
            }
            return $scope.instrument.key.replace( "/", "-" ) + "-" + lang + ".docx";
        };

        $scope.getSupportFileName = function( lang, ext ) {
            if( !$scope.instrument.key ) {
                return "support_" + lang + "." + ext;
            }
            return $scope.instrument.key.replace( "/", "-" ) + "-support-" + lang + "." + ext;
        };

        function getEditor() {
          var existingEditor = tinymce.get('editor');
          if(existingEditor) return Promise.resolve(existingEditor);

          return tinymce.init({
            selector: '#editor',
            plugins: 'code link lists paste image',
            toolbar: 'bold italic underline strikethrough | superscript subscript | alignleft aligncenter outdent indent | numlist bullist | fontselect fontsizeselect formatselect | link image | removeformat',
            menubar: '',
            height: '100%',
            paste_data_images: true,
            file_picker_types: 'image',
            file_picker_callback: function (cb, value, meta) {
              var input = document.createElement('input');
              input.setAttribute('type', 'file');
              input.setAttribute('accept', 'image/*');

              input.onchange = function () {
                var file = this.files[0];
                var reader = new FileReader();
                reader.onload = function () {
                  var id = 'blobid' + (new Date()).getTime();
                  var blobCache =  tinymce.activeEditor.editorUpload.blobCache;
                  var base64 = reader.result.split(',')[1];
                  var blobInfo = blobCache.create(id, file, base64);
                  blobCache.add(blobInfo);
                  cb(blobInfo.blobUri(), { title: file.name });
                };
                reader.readAsDataURL(file);
              };

              input.click();
            }
          }).then(function(editors) {
            return editors[0];
          })
        }

        $scope.changePivot = function( pivot ) {
            $scope.hideLoader();

            var sp = $scope.pivots.find( function( p ) { return p.id = pivot } );
            if( sp.id === 'adherents' ) {
                $scope.showLoader();
            }
            $scope.selectedPivot = sp;
        };

        $scope.editBodyText = function( langIndex ) {
          getEditor().then(function(editor) {
            var uri = $scope.instrument.bodyText.ref[ langIndex ].uri;
            if( uri ) {
              $http.get( uri ).then( function( response ) {
                editor.setContent(response.data);
              } );
            } else {
              editor.setContent( '<p></p>', 'api' );
            }
            $scope.showTextEditor = true;
          });
        };

        $scope.closeBodyTextEditor = function(  ) {
            $scope.showTextEditor = false;
        }

        $scope.saveBodyTextEdits = function( lang ) {
            $scope.showTextEditor = false;
            getEditor().then(function (editor) {
              var data = editor.getContent();
              $scope.upload(  UploadService.createFile( data, "main-text-" + lang + ".html", "text/html" ), "mainText", lang  )
            });
        };

        $scope.buildOlisUrl = function( uri, lang ) {
            return HelperService.buildOlisUrl( uri, lang );
        };

        $scope.openOlisUrl = function( uri, lang ) {
            var url = HelperService.buildOlisUrl( uri, lang );
            if( url ) {
                $window.open( url, '_blank' );
            }
        };

        $scope.$on('ngRepeatFinished', function (ngRepeatFinishedEvent) {
            $scope.hideLoader();
        });

        $scope.showLoader = function () {
            $scope.loading = true;
            var spe = angular.element( document.querySelector( '#body' ) )
            spe.append('<div id="loader" class="loader"></div>');
        };

        $scope.hideLoader = function () {
            $scope.loading = false;
            var loader = angular.element( document.querySelector( '#loader' ) );

            if( loader ) {
                if( loader.fadeOut ) {
                    loader.fadeOut( function() {
                        loader.remove();
                    } );
                } else {
                    loader.remove();
                }
            }
        };

        $scope.pivots = [ {
            id: "overview",
            title: "Overview",
            selected: true
        }, {
            id: "dates",
            title: "Date(s) / Reference(s)"
        }, {
            id: "mainText",
            title: "Text"
        }, {
            id: "supportDocuments",
            title: "Brochure"
        }, {
            id: "backgroundInformation",
            title: "Background information"
        }, {
            id: "adherents",
            title: "Adherents / Parties"
        }, {
            id: "monitoring",
            title: "Monitoring"
        }, {
            id: "leg",
            title: "LEG Internal Notes"
        } ];

        $scope.langPivots = [ {
            id: "en",
            title: "English",
            selected: true
        }, {
            id: "fr",
            title: "French"
        } ];

        $scope.lists = {
            themes: [],
            otherCommittees: [],
            committees: [],
            instrumentStatusTypes: []
        };

        $scope.instrument = {}; //to initiate watch on UI before loading
        //english only
        $rootScope.lang = "en";
        $rootScope.langIndex = 0;

        $scope.newDocument = {
            uri: "",
            description_en: "",
            description_fr: "",
            allowUriEdit: true
        };

        $scope.showDocumentModal = false;
        $scope.loading = true;

        if( $state.current.name === "app.instrumentEdit" ) {
            InstrumentService.fetch( $stateParams.id, true ).then( function( formatted ) {
                $scope.prepareForEdit( formatted );
                $scope.loading = false;
            }, function( e ) {
                $scope.loading = false;
            } );
        } else if( $state.current.name === "app.instrumentCreate" ) {
            InstrumentService.createNew().then( function( formatted ) {
                $scope.prepareForEdit( formatted );
                $scope.loading = false;
            }, function( e ) {
                $scope.loading = false;
            } );
        }

      // TODO: DELETE AFTER MONITORING MIGRATION
        $scope.updateMonitoringComments = function( text, lang, newText ) {};

      // TODO: DELETE AFTER MONITORING MIGRATION
      $scope.deleteMonitoringEntry = function( index ) { };

      // TODO: DELETE AFTER MONITORING MIGRATION
      $scope.updateMonitoringRef = function( ref, idx, newRef ) {};

      // TODO: DELETE AFTER MONITORING MIGRATION
      $scope.addNewMonitoring = function() {};

        $scope.updateAdherenceComments = function( adherence, lang, newText ) {
            var idx = lang == 'en' ? 0 : 1;
            if( adherence.comments.text.length <= idx ) {
                adherence.comments.text.push( { lang: lang,  value: newText } );
            } else {
                adherence.comments.text[ idx ].value = newText;
            }

        };

        $scope.closeUploadDialog = function() {
            $scope.showDocumentModal = false;
        };

        $scope.$watch( "instrument", function() {
            // We update tab links that have an invalid field inside them

            // Remove existing invalid classes
            var tabLinks = document.getElementsByClassName( "ms-Pivot-link" );
            angular.forEach( tabLinks, function( tabLink ) {
                angular.element( tabLink ).removeClass( "contains-invalid-fields" );
            } );

            // Get fields with invalid class
            var invalidFields = document.getElementsByClassName( "is-invalid" );

            // Find their tab
            angular.forEach( invalidFields, function( invalidField ) {
                var invalidTab = getAncestor( angular.element( invalidField )[ 0 ], "tab" );

                var tabName = angular.element( invalidTab ).attr( "data-name" );
                var index = findWithAttr( $scope.pivots, "id", tabName );
                angular.element( tabLinks[ index ] ).addClass( "contains-invalid-fields" );
            } );
        }, true );
} );
    
