app.service('CopyToClipboardService', function ($window, $q) {
  var body = angular.element($window.document.body);
  var textarea = angular.element('<textarea/>');
  textarea.css({
    position: 'fixed',
    opacity: '0'
  });

  return function (text) {
    textarea.val(text);
    body.append(textarea);
    textarea[0].select();

    return($q(function(resolve, reject) {
      try {
        var successful = document.execCommand('copy');
        if (!successful) throw successful;
        resolve(true);
      } catch (err) {
        reject();
      }

      textarea.remove();
    }));
  }
});
