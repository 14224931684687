app.controller( 'HeaderCtrl', function( $rootScope, $scope, $document, $state, $interval, $window, AuthService, SubscriptionService, HelperService ) {

    var self = this;

    self.loginAttemptCount = 0;
    $rootScope.mterm = "";


    self.changeLanguage = function( lang ) {
        $state.go( $state.current.name, { lang: lang } );
    };

    self.search = function( term ) {
        params = {
            mode: 'advanced',
            searchScope: "fullText"
        };

        params[ HelperService.inferSearchParamName( term ) ] = term;
        $state.go( 'app.instruments', params );
    };

    self.login = function() {
        AuthService.ssoLogin();
    };

    self.logout = function() {
        AuthService.logout().then( function() {
            $rootScope.user = $rootScope.anonymous;
            $state.reload();
            // TODO : Add Success Flash message
        } );
    };

    $scope.toggleLinksMenu = function() {

        var menu = angular.element( document.querySelector( '#links-menu-' + $rootScope.lang ) )

        if( angular.element( menu ).hasClass( 'open' ) ) {
            angular.element( menu ).removeClass( 'open' );
        } else {
            angular.element( menu ).addClass( 'open' );
        }
    }

} );
