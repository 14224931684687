app.service( 'CommitteeService', function( API_BASE_URL, $http, $rootScope ) {
    
    var serviceBaseUrl = API_BASE_URL + 'committees';
    
    this.list = function( term, ids ) {
        return $http.get( serviceBaseUrl, { params: { term: term, ids: ids, lang: $rootScope.lang } } ).then( function( results ) {
            return results.data;
        } );
    };
} );
