app.controller( 'UnsubscribeCtrl',
    function( $scope,
              SubscriptionService) {

        $scope.unsubscribed = false;
        SubscriptionService.unsubscribe().then( function() {
                $scope.unsubscribed = true;
          }
        );
    } );
