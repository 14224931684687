app.controller( "SettingsCtrl", function( $scope, $rootScope, $state, $filter, ConceptService, FlashMessageService ) {
    

    // allowed users only
    if( !$rootScope.user.canDo( "editMetaLists" ) ) {
        return $state.go( "app.403" );
    }

    $scope.watchSelected = function () {
        if( $scope.activeConcept && $scope.activeConcept.modified === undefined ) {
            var unwatch = $scope.$watch( 'activeConcept', function( concept ) {
                if( concept ) {
                    concept.modified = true;
                    if( concept.unwatch ) {
                        concept.unwatch();
                        delete concept.unwatch;
                    }
                }
            } );
        }
    };


    //english only

    ConceptService.list().then( function( concepts ) {
        // we sort elements by sort order in current interface language
        var sortByProperty = "englishSortOrder";
        $scope.concepts = {};
        angular.forEach( concepts, function( concept ) {
            concept.elements = $filter( "orderBy" )( concept.elements, sortByProperty );
            $scope.concepts[ concept.id ] = concept;
        } );
        $scope.concepts.list = concepts;
    
        $scope.loading = false;
        $scope.selectedConcept = "";
        $scope.$watch( 'selectedConcept', function( newVal, oldVal ){
            $scope.loading = true;
            $scope.activeConcept = $scope.concepts[ newVal ];
            var oldConcept = $scope.concepts[ oldVal ];

            if( oldConcept && oldConcept.unwatch ) {
                oldConcept.unwatch()
                delete oldConcept.unwatch;
            }
            $scope.watchSelected();
        } );
    } );

    $scope.listLoaded = function() {
        $scope.loading = false;
        return true;
    };
    
    $scope.update = function( concepts ) {
        ConceptService.update( concepts ).then( function( results ) {
            FlashMessageService.show( "Lists updated successfully", "success" );
            if( $scope.activeConcept ) {

            }
        }, function( error ) {
            FlashMessageService.show( "Lists could not be updated due to a server error. Please try again", "error" );
        } );
    };
    
    $scope.cancelChanges = function() {
        $scope.isConfirmationDialogOpen = false;
        $state.reload();
        FlashMessageService.show( "Changes have been canceled", "success" );
    };
    
    $scope.addNewElement = function( concept ) {
        //allow editing ids for countries and committees
        var nid = 0;
        if( !(concept.id === 1 ||  concept.id === 2) ) {
            var max = 0;
            var id = 0;
            for( ii = 0; ii < concept.elements.length; ii += 1 ) {
                id = concept.elements[ ii ].id;
                if( id > max ) {
                    max = id;
                }
            }
            nid = max + 1;
        }
    
        var ne = null;
        if( concept.elements.length ) {
            ne = angular.copy( concept.elements[ concept.elements.length - 1 ] );
            ne.id = nid;
            ne.englishName = "";
            ne.frenchName = "";
        } else {
            ne = {
                id: nid,
                active: false,
                englishName: "",
                frenchName: "",
                englishSortOrder: 0,
                frenchSortOrder: 0
            };
        }
        ne.idEditable = nid === 0;
        concept.elements.push( ne );
    };
    
    $scope.hideModal = function() {
        $scope.isConfirmationDialogOpen = false;
    };
} );
