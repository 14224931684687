app.service('RecentDevelopmentService', function (API_BASE_URL, $http, $rootScope) {

  var serviceBaseUrl = API_BASE_URL + 'recent-developments';

  this.list = function () {
    return $http.get(serviceBaseUrl, {params: {lang: $rootScope.lang}}).then(function (results) {
      return results.data;
    });
  };

});
