app.component( 'fileUploader', {
    templateUrl: '/templates/comp/file-uploader.html',
    bindings: {
        noFileMsg: '@',
        uploadLabel: '@',
        descLabelEn: '@',
        descLabelFr: '@',
        imagePreview: '@',
        acceptedTypes: '@',
        documentType: '@',
        title: '@',
        uri: '<',
        lang: '@',
        loading: '=',
        onUpload: '&'
    },

    controller: function( $scope, $filter, $sce, $timeout, UploadService, FlashMessageService ) {
        var ctrl = this;
        ctrl.imagePreview = false;

        ctrl.editorVisible = false;
        ctrl.editorHtml = "";
        ctrl.excerpt = "";
        ctrl.file = null;
        ctrl.title = "";
        ctrl.acceptedTypes = ".*";
        ctrl.descLabelEn = "";
        ctrl.descLabelFr = "";
        ctrl.uploadLabel = "";
        ctrl.documentType = "file";
        ctrl.imagePreviewHtml = ""

        ctrl.emptyRef = {
            lang: "en",
            uri:  "",
            format: 'file',
            desc: [ { lang: "en", value: ""}, { lang: "fr", value: ""} ],
            narrative: [ { lang: "en", value: ""}, { lang: "fr", value: ""} ],
        };

        ctrl.ref = angular.copy( ctrl.emptyRef );

        ctrl.removeFile = function() {
            ctrl.file = null;
            ctrl.ref.uri = "";
        };


        ctrl.updateControl = function() {
            ctrl.ref.lang = ctrl.lang;
            ctrl.ref.uri = $sce.trustAsUrl( ctrl.uri );
            ctrl.ref.desc[0].value = ctrl.descEn;
            ctrl.ref.desc[1].value = ctrl.descFr;
            ctrl.ref.narrative[ 0 ].value = $sce.trustAsHtml( ctrl.narrativeEn );
            ctrl.ref.narrative[ 1 ].value = $sce.trustAsHtml( ctrl.narrativeFr );
            if( ctrl.ref.uri && ctrl.imagePreview ) {
                ctrl.imagePreviewHtml = $sce.trustAsHtml( "<img src='" + ctrl.ref.uri + " }}'/>" );
            } else {
                ctrl.imagePreviewHtml = "";
            }
        }

        ctrl.deleteFile = function() {
            if( !ctrl.onUpload ) {
                return;
            }
            ctrl.ref = angular.copy( ctrl.emptyRef );
            ctrl.onUpload( { value: angular.copy( ctrl.ref ) } );
        };

        ctrl.uploadFile = function( ) {
            if( !ctrl.file || !ctrl.onUpload ) {
                return;
            }

            ctrl.loading = true;
            return UploadService.upload( ctrl.file, ctrl.documentType ).then( function( response ) {
                var resp = response.data;
                return $timeout( function() {
                    ctrl.loading = false;

                    ctrl.ref.format = resp.format;
                    ctrl.ref.uri = resp.temporaryLocation;

                    ctrl.file = null;
                    FlashMessageService.show( "upload successful", "success" );
                    ctrl.onUpload( { value: angular.copy( ctrl.ref ) } );
                } );
            }, function( error ) {
                ctrl.loading = false;
                return FlashMessageService.show( "upload failed: " + error.statusText, "error" );
            } );
        };

        ctrl.$onInit = function() {
            ctrl.updateControl();
        };
    }
});