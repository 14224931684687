app.component( 'textEditor', {
    templateUrl: '/templates/comp/text-editor.html',
    bindings: {
        editorHtml: '<',
        dialogTitle: '<',
        onSave: '&'
    },
    controller: function( $scope, $filter, $sce ) {
        var ctrl = this;

        ctrl.editorVisible = false;
        ctrl.editorHtml = "";
        ctrl.excerpt = "";

        ctrl.showTextEditor = function() {
            ctrl.editorVisible = true;
        };

        ctrl.saveEdits = function() {
            ctrl.editorVisible = false;
            if( ctrl.onSave ) {
                ctrl.onSave( { value: ctrl.editorHtml } );
            }
            ctrl.buildExcerpt();
        };

        ctrl.cancelEdits = function() {
            ctrl.editorVisible = false;
        };

        ctrl.deleteText = function() {
            ctrl.editorHtml = "";
            ctrl.saveEdits();
        };


        ctrl.extractText = function( s ) {
            var span= document.createElement('span');
            span.innerHTML= s;
            return span.textContent || span.innerText;
        };

        ctrl.buildExcerpt = function() {
            if( ctrl.editorHtml ) {
                ctrl.excerpt = $sce.trustAsHtml( $filter( "truncate" )( ctrl.extractText( ctrl.editorHtml.toString() ), true, 100 ) );
            } else {
                ctrl.excerpt = "";
            }
        }

        ctrl.$onInit = function() {
            ctrl.buildExcerpt();
        };
    }
});