app.service('StatService', function (API_BASE_URL, $http, $rootScope) {

  var serviceBaseUrl = API_BASE_URL + 'stats';

  this.show = function (id) {
    return $http.get(serviceBaseUrl + '/' + id, {params: {lang: $rootScope.lang}}).then(function (results) {
      return results.data;
    });
  };

});
