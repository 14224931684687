app.controller('PrintoutInstrumentsCtrl', function ($scope, $q, $http, $window, $filter, $location, $stateParams, PrintoutService, FlashMessageService) {

  var self = this;

  // simple JS print of selected Instruments
  self.printList = function () {
    $window.print();
  };

  //share instrument list by mail using backend service
  self.shareListByMailViaBackend = function (instruments) {
    promptRecipients()
      .then(function (recipients) {
        return PrintoutService.sendInstrumentsEmailViaBackend(instruments, recipients)
      })
      .then(function (resp) {
        FlashMessageService.show(
          $filter('translate')('printout.shareListByMailViaBackendSuccess'),
          'success'
        );
      })
      .catch(function (err) {
        var errorDescription = '';
        if (err) {
          errorDescription = _.isString(err) ? err : '';
          if (err.message) errorDescription = err.message;
          if (err.statusText) errorDescription = err.statusText;
          if (err.translationCode) errorDescription = $filter('translate')('printout.validation.' + err.translationCode);
        }
        if (errorDescription) {
          FlashMessageService.show(
            $filter('translate')('printout.shareListByMailViaBackendError', { errorDescription: errorDescription }),
            'error'
          );
        }
      });
  };

  // Create a custom printout from server and display it
  self.printInstrumentList = function (instruments) {

    if (!instruments.length) {
      return false;
    }

    var instrumentIds = [];

    angular.forEach(instruments, function (instrument) {
      instrumentIds.push(instrument.id);
    });

    PrintoutService.create(instrumentIds, $stateParams.mode === "publish" && $scope.searchData.published === "false");

  };

  self.sendListByEmail = function (instruments) {

    if (!instruments.length) {
      return false;
    }

    var instrumentsTable = '';
    angular.forEach(instruments, function (instrument) {
      instrumentsTable += '\n'
        + instrument.title + '\n'
        + instrument.key + '\n'
        + 'https://legalinstruments.oecd.org/' + $scope.lang + '/instruments/' + instrument.key.replaceAll('/', '-') + '\n'
    });

    var subject = '';
    if ($scope.langIndex == 0) {
      instrumentsTable += '\n\nSource: Compendium of Legal Instruments of the OECD\nhttps://legalinstruments.oecd.org';
      if (instruments.length == 1) {
        subject = 'OECD Legal Instrument ' + instruments[0].key;
      } else {
        subject = 'Selected Instruments from the Compendium of Legal Instruments of the OECD';
      }
    } else {
      instrumentsTable += '\n\nSource : Recueil des Instruments Juridiques de l’OCDE\nhttps://legalinstruments.oecd.org';
      if (instruments.length == 1) {
        subject = 'Instrument juridique de l’OCDE ' + instruments[0].key;
      } else {
        subject = 'Sélection d\'instruments du Recueil d\'Instruments Juridiques de l\'OCDE';
      }
    }

    self.sendEmail(subject, instrumentsTable);
  };

  self.sendSingleItemByEmail = function () {
    self.sendListByEmail([{ title: $scope.instrument.title.name[$scope.langIndex].value, key: $scope.instrument.key }]);
  };

  self.sendEmail = function (subject, message) {
    message = message.replaceAll("\n", "%0D%0A");
    $window.open("mailto:?subject=" + subject + "&body=" + message, "_self");
  };

  function promptRecipients() {
    return $q(function (resolve, reject) {
      var recipientsStr = $window.prompt(
        $filter('translate')('printout.shareListByMailRecipients'),
        $scope.invalidRecipientsStr || ''
      );
      if (!_.isEmpty(recipientsStr)) {
        var recipients = recipientsStr.split(';').map(function (i) {
          return i.trim()
        });

        var validRecipients = _.filter(recipients, validateEmail);
        if (_.size(validRecipients) === _.size(recipients)) {
          $scope.invalidRecipientsStr = null;
          return resolve(validRecipients);
        }

        $scope.invalidRecipientsStr = recipientsStr;
        var invalidEmails = _.difference(recipients, validRecipients);

        var alertMessage = $filter('translate')('printout.validation.emailValidationFailed') + '\n\n';
        alertMessage += $filter('translate')('printout.validation.emailsExcluded', { mails: invalidEmails.join('; ') }) + '\n\n';

        if (!_.isEmpty(validRecipients)) {
          alertMessage += $filter('translate')('printout.validation.emailsIncluded', { mails: validRecipients.join('; ') })
          if ($window.confirm(alertMessage)) {
            $scope.invalidRecipientsStr = null;
            return resolve(validRecipients);
          } else {
            return reject();
          }
        } else {
          $window.alert(alertMessage);
          reject({
            message: 'no recipients',
            translationCode: 'noRecipients'
          });
        }
      }
      return reject();
    });
  }


});

function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
