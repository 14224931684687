app.component( 'refEditor', {
    templateUrl: '/templates/comp/ref-editor.html',
    bindings: {
        dialogTitle: '<',
        uriLabel: '<',
        descLabel: '<',
        noFileMsg: '@',
        uploadLabel: '@',
        acceptedTypes: '@',
        showUri: '<',
        showNarrative: '<',
        showTitle: '<',
        showViewer: '<',
        ref: '<',
        onSave: '&',
        onDelete: '&'
    },

    controller: function( $scope, $timeout, UploadService, FlashMessageService  ) {
        var ctrl = this;

        ctrl.emptyRef =  {
            lang: "en",
            uri: "",
            desc: [ { lang:"en", value: "" }, { lang: "fr", value: "" } ],
            narrative: [ { lang:"en", value: "" }, { lang: "fr", value: "" } ]
        };
        ctrl.file = null;
        ctrl.showTitle = false;
        ctrl.showUri = true;
        ctrl.showNarrative = false;
        ctrl.descLabel = 'Description';
        ctrl.uriLabel = "URL or ONE Reference";

        ctrl.editorVisible = false;
        ctrl.ref = ctrl.emptyRef;
        ctrl.eref = ctrl.emptyRef;

        ctrl.editRef = function() {
            if( !ctrl.ref ) {
                ctrl.ref = ctrl.emptyRef;
            }
            ctrl.eref = angular.copy( ctrl.ref );
            ctrl.ensureGoodReference( ctrl.eref );
            ctrl.eref.format = "";
            ctrl.editorVisible = true;
        };
        
        ctrl.ensureGoodReference = function( ref ) {
            if( !ref.desc ) {
                ref.desc = [];
            }
            if( ref.desc.length == 0 ) {
                ref.desc.push( { lang:"en", value: "" } )
            }
            if( ref.desc.length == 1 ) {
                ref.desc.push( { lang:"fr", value: "" } )
            }
            ref.desc[ 0 ].lang = "en";
            ref.desc[ 1 ].lang = "fr";

            if( !ref.narrative ) {
                ref.narrative = [];
            }
            if( ref.narrative.length == 0 ) {
                ref.narrative.push( { lang:"en", value: "" } )
            }
            if( ref.narrative.length == 1 ) {
                ref.narrative.push( { lang:"fr", value: "" } )
            }
            ref.narrative[ 0 ].lang = "en";
            ref.narrative[ 1 ].lang = "fr";

            if( !ref.uri ) {
                ref.uri = "";
            }

            if( !ref.lang ) {
                ref.lang = "en";
            }
            if( !ref.format ) {
                ref.format = "";
            }

            if( ref.format.length === 0 ) {
                var luri = ref.uri.toLowerCase();
                if( ctrl.isFile( ref ) ) {
                    ref.format = "file";
                } else if( luri.startsWith( "http://" ) || luri.startsWith( "https://" ) ) {
                    ref.format = "link";
                } else {
                    ref.format = "OLISRef";
                }
            }


        };

        ctrl.deleteRef = function() {
            ctrl.editorVisible = false;
            if( ctrl.onDelete ) {
                ctrl.onDelete( { value: ctrl.ref } );
            }
        };

        ctrl.save = function() {
            ctrl.editorVisible = false;
            ctrl.ensureGoodReference( ctrl.eref );
            ctrl.ref = ctrl.eref;
            if( ctrl.onSave ) {
                ctrl.onSave( { value: ctrl.ref } );
            }
        };

        ctrl.close = function() {
            ctrl.editorVisible = false;
        };

        ctrl.uploadFile = function( ) {
            if( !ctrl.file  ) {
                return;
            }

            ctrl.loading = true;
            return UploadService.upload( ctrl.file, ctrl.documentType ).then( function( response ) {
                var resp = response.data;
                return $timeout( function() {
                    ctrl.loading = false;

                    if( !ctrl.eref ) {
                        ctrl.eref = angular.copy( ctrl.emptyRef );
                    }
                    ctrl.ensureGoodReference( ctrl.eref );

                    ctrl.eref.uri = resp.temporaryLocation;
                    if( !ctrl.eref.desc[ 0 ].value ) {
                        ctrl.eref.desc[ 0 ].value = ctrl.file.name;
                    }
                    if( !ctrl.eref.desc[ 1 ].value ) {
                        ctrl.eref.desc[ 1 ].value = ctrl.file.name;
                    }

                    ctrl.eref.format = "file";
                    ctrl.file = null;
                    FlashMessageService.show( "upload successful", "success" );
                } );
            }, function( error ) {
                ctrl.loading = false;
                return FlashMessageService.show( "upload failed: " + error.statusText, "error" );
            } );
        };

        ctrl.isFile = function( ref ) {
            if( !ref || !ref.uri ) {
                return false;
            }
            return ref.uri.startsWith( '/temp/' )
                || ref.uri.startsWith( '/public/' )
                || ref.uri.startsWith( '/private/' )
        }

        ctrl.$onInit = function() {
            if( !ctrl.ref ) {
                ctrl.ref = ctrl.emptyRef;
            }
            if( typeof( ctrl.showUri ) == 'undefined' ) {
                ctrl.showUri = true;
            }
            if( typeof( ctrl.uriLabel ) == 'undefined' ) {
                ctrl.uriLabel = "URL or ONE Reference";
            }

            if( typeof( ctrl.acceptedTypes ) == 'undefined' ) {
                ctrl.acceptedTypes = ".docx,.pdf,.html,.htm,.xhml";
            }

            if( typeof( ctrl.showViewer ) == 'undefined' ) {
                ctrl.showViewer = true;
            }

            if( typeof( ctrl.descLabel ) == 'undefined' ) {
                ctrl.descLabel = "Description";
            }

            if( ctrl.ref.isNew ) {
                ctrl.eref = angular.copy( ctrl.emptyRef );
                ctrl.editorVisible = true;
            }
        };
    }
});