(function (window) {
  window.__env = window.__env || {};

  // API url
  window.__env.apiBaseUrl = '/api/';
  window.__env.ssoLoginUrl = '/login?token=0';

  window.FabricConfig = {
        fontBaseUrl: "/www/dist/fonts"
  };

}(this));
