app.service('AdherenceService', function (API_BASE_URL, $http, $rootScope) {

  var serviceBaseUrl = API_BASE_URL + 'adherences';

  this.listGroupedByCountry = function (params) {
    params = "undefined" !== typeof params ? params : {};
    params.lang = $rootScope.lang;
    return $http.get(serviceBaseUrl, {params: params}).then(function (results) {
      return results.data;
    });
  };

});
