app.service('UploadService', function (API_BASE_URL, Upload) {

  var serviceBaseUrl = API_BASE_URL + 'upload';

  this.upload = function (file, uploadType) {
    return Upload.upload({
      url : serviceBaseUrl,
      data: {file: file, uploadType: uploadType}
    })
  };


  this.createFile = function( data, fileName, mimeType ) {
      var file = null;
      try {
          file = new Blob( [data], { type: mimeType } );
      } catch (e) {
          // The BlobBuilder API has been deprecated in favour of Blob, but older
          // browsers don't know about the Blob constructor
          // IE10 also supports BlobBuilder, but since the `Blob` constructor
          //  also works, there's no need to add `MSBlobBuilder`.
          var BlobBuilder = window.WebKitBlobBuilder || window.MozBlobBuilder;
          var bb = new BlobBuilder();
          bb.append( data );
          file = bb.getBlob (mimeType );
      }
      file.name = fileName;
      return file;
  };

});
